import React, {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListComponent from '../ListComponent/ListComponent';
import {uploadVehicleDocument} from '../../models/StepsSendRules';
import AppButton from '../AppButton/AppButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import {useNavigate, useParams} from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {StoreChecklist} from '../../models/StoreChecklist';
import {useStoreChecklist} from '../../hooks/useChecklist';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import GetBase64Extension from '../../utils/GetBase64Extension';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {CircularProgress, ListItemText} from '@mui/material';
import {FileToBase64} from '../../utils/FileToBase64';

import './UploadVehicleDocument.scss';
import ChecklistService from '../../services/ChecklistService';
import {CheckListItems} from '../../enums/ChecklistItems';
import SendConfirm from '../SendConfirm/SendConfirm';
import DialogInfo from '../DialogInfo/DialogInfo';
import IFile from '../../interfaces/IFile';

interface UploadVehicleDocumentProps {
}


const UploadVehicleDocument: FC<UploadVehicleDocumentProps> = () => {
    const [payload, setPayload] = useState<StoreChecklist>({} as StoreChecklist);
    const [{guid} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const {mutate, isSuccess, isError, error, isLoading} = useStoreChecklist(payload);
    const [file, setFile] = useState<IFile>({} as IFile);
    const {item} = useParams<string>();
    const navigate = useNavigate();
    const [isLoadingCheckList, setIsLoadingCheckList] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showError, setShowError] = useState<boolean>(false);

    const handleSubmit = () => mutate(payload);

    const handleSelect = async (e: any) => {
        const _file = e.files[0];

        if (!_file) return;

        const _base64 = await FileToBase64(_file) || '';

        setFile({
            name: _file.name,
            base64: _base64,
            extension: GetBase64Extension(_base64),
        });
        !!_base64 && item && setPayload({file: _base64, guid: guid, item: +item});
    }

    const confirmSend = () => {
        setShowDialog(true);
    }

    useEffect(() => {
        isSuccess && navigate('/checklist');
        isError && setShowError(true);
    }, [isSuccess, isError]);

    useEffect(() => {
        setIsLoadingCheckList(true);
        ChecklistService.getChecklist(guid).then(x => {
            const _item = x?.produto?.checklist.filter((y: any) => y.tipo === CheckListItems.DOC_VEICULO);
            if (_item && _item[0].checked) {
                navigate(`/checklist`);
            }
            setIsLoadingCheckList(false);
        }).catch(e => {
            setIsLoadingCheckList(false);
            navigate(`/checklist`);
        });
    }, []);

    return (
        <div className="UploadVehicleDocument" data-testid="UploadVehicleDocument">
            {isLoading ?
                (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress/>
                    </Grid>
                ) :
                (<>
                    {!isLoadingCheckList && (
                        <Grid
                            item
                            xs={12}
                            marginTop="20px"
                            className="content-body"
                        >
                            {!file?.base64 && (<Typography variant="h4">Siga as instruções</Typography>)}
                            {file?.base64 ? (
                                <>
                                    <Typography variant="subtitle1" marginTop="20px">Verifique se o arquivo está
                                        correto, caso
                                        precise, você pode selecionar um outro arquivo.</Typography>

                                    <Grid
                                        item
                                        xs={12}
                                        marginTop="20px"
                                        className="content-body"
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            className="img-box preview"
                                            style={{marginBottom: 20}}
                                        >
                                            {!error ? (
                                                <>
                                                    {file?.extension === 'pdf' ? (
                                                        <List
                                                            className="ListComponent"
                                                            data-testid="ListComponent"
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: 360,
                                                                bgcolor: 'background.paper',
                                                            }}
                                                            style={{padding: 0}}
                                                        >
                                                            <ListItem
                                                                alignItems="center"
                                                                style={{padding: 0}}
                                                            >
                                                                <ListItemAvatar>
                                                                    <PictureAsPdfIcon fontSize="large"/>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={file?.name}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    ) : (<img src={file?.base64} alt={'snap'} style={{width: '90%'}}/>)}
                                                </>
                                            ) : (
                                                <Typography variant="body1"
                                                            className="error">{error?.response?.data?.message}</Typography>
                                            )}
                                        </Box>

                                        <AppButton
                                            title={'Enviar'}
                                            onClick={() => confirmSend()}
                                            icon={<FileUploadIcon fontSize="large"/>}
                                            style={{width: '90%'}}
                                            disabled={isLoading}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <AppButton
                                                title={'Escolher outro arquivo'}
                                                className="btn-gray"
                                                style={{width: '90%'}}
                                            />
                                        </label>
                                        <SendConfirm status={showDialog} setStatus={setShowDialog}
                                                     body={'Deseja realmente enviar o arquivo?'}
                                                     handleAction={handleSubmit}/>
                                        <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                                    corpo={error?.response?.data?.message || "Não foi possível enviar o arquivo!"}
                                                    botao={"Ok"}/>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Box
                                        justifyContent="center"
                                        flexDirection="column"
                                        className="img-box"
                                        marginTop="20px"
                                    >
                                        <Typography variant="h6">Se atente aos cuidados<br/>abaixo para enviar o<br/>documento
                                            do
                                            seu veículo:</Typography>

                                        <ListComponent
                                            data={uploadVehicleDocument}
                                        />
                                    </Box>

                                    <Grid
                                        item
                                        xs={12}
                                        marginTop="30px"
                                        className="content-body"
                                    >
                                        <label htmlFor="icon-button-file">
                                            <AppButton
                                                title={'Escolher arquivo'}
                                                icon={<ArrowRight fontSize="large"/>}
                                            />
                                        </label>
                                    </Grid>
                                </>
                            )}

                            <input
                                accept="application/pdf,image/jpeg,image/jpg,image/png"
                                id="icon-button-file"
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelect(e.target)}
                            />
                        </Grid>
                    )}
                </>)}
        </div>
    );
}

export default UploadVehicleDocument;
