import React, {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import {captureEngine} from '../../models/StepsSendRules';
import {useCamera} from '../../providers/CameraProvider';
import AppButton from '../AppButton/AppButton';
import CameraComponent from '../CameraComponent/CameraComponent';
import ListComponent from '../ListComponent/ListComponent';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import ArrowRight from '@mui/icons-material/ArrowRight';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import './CaptureEngine.scss';
import {StoreChecklist} from '../../models/StoreChecklist';
import {useStoreChecklist} from '../../hooks/useChecklist';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import ChecklistService from '../../services/ChecklistService';
import {CheckListItems} from '../../enums/ChecklistItems';
import SendConfirm from '../SendConfirm/SendConfirm';
import DialogInfo from '../DialogInfo/DialogInfo';
import {CircularProgress} from '@mui/material';

interface CaptureEngineProps {
}

const CaptureEngine: FC<CaptureEngineProps> = () => {
    const [payload, setPayload] = useState<StoreChecklist>({} as StoreChecklist);
    const {mutate, isSuccess, isError, isLoading, error} = useStoreChecklist(payload);
    const [{guid} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const {source, openCam, setOpenCam, resetCam} = useCamera();
    const {item} = useParams<string>();
    const navigate = useNavigate();
    const [isLoadingCheckList, setIsLoadingCheckList] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showError, setShowError] = useState<boolean>(false);

    const handleSubmit = () => mutate(payload);

    useEffect(() => {
        source && setPayload({
            guid: guid,
            item: +(item || '0'),
            file: source,
        });
    }, [source]);

    const confirmSend = () => {
        setShowDialog(true);
    }

    useEffect(() => {
        isSuccess && navigate('/checklist');
        isError && setShowError(true);
    }, [isSuccess, isError]);

    useEffect(() => {
        setIsLoadingCheckList(true);
        ChecklistService.getChecklist(guid).then(x => {
            const _item = x?.produto?.checklist.filter((y: any) => y.tipo === CheckListItems.MOTOR_VEICULO);
            if (_item && _item[0].checked) {
                navigate(`/checklist`);
            }
            setIsLoadingCheckList(false);
        }).catch(e => {
            setIsLoadingCheckList(false);
            navigate(`/checklist`);
        });
        resetCam();
    }, []);

    return (
        <div className="CaptureEngine" data-testid="CaptureEngine">
            {isLoading ?
                (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress/>
                    </Grid>
                ) :
                (<>
                    {!openCam && !isLoadingCheckList && (
                        <Grid
                            item
                            xs={12}
                            marginTop="20px"
                            className="content-body"
                        >
                            {!source && (<Typography variant="h4">Siga as instruções</Typography>)}

                            {source ? (
                                <Typography variant="subtitle1" marginTop="20px">Verifique se a foto está nítida,<br/>caso
                                    precise, você pode tirar uma nova foto.</Typography>
                            ) : (
                                <>
                                    <Box
                                        justifyContent="center"
                                        flexDirection="column"
                                        className="img-box"
                                        marginTop="20px"
                                    >
                                        <Typography variant="h6">Se atente aos cuidados<br/>abaixo para tirar foto
                                            do<br/>motor
                                            do seu carro:</Typography>

                                        <ListComponent
                                            data={captureEngine}
                                        />
                                    </Box>
                                </>
                            )}
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        marginTop="20px"
                        className="content-body"
                    >
                        {!openCam && source && !isLoadingCheckList && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                className="img-box preview"
                            >
                                <img src={source} alt={'snap'} style={{width: '90%'}}/>
                            </Box>
                        )}
                    </Grid>

                    {!isLoadingCheckList && (
                        <Grid
                            item
                            xs={12}
                            marginTop="30px"
                            className="content-body"
                        >
                            {openCam ? (
                                <CameraComponent
                                    mode={'environment'}
                                    overlay={false}
                                />
                            ) : (
                                <>
                                    {!source ? (
                                        <AppButton
                                            title={'Começar'}
                                            onClick={() => setOpenCam(true)}
                                            icon={<ArrowRight fontSize="large"/>}
                                        />
                                    ) : (
                                        <>
                                            <AppButton
                                                title={'Enviar'}
                                                onClick={() => confirmSend()}
                                                icon={<FileUploadIcon fontSize="large"/>}
                                                style={{width: '80%'}}
                                                disabled={isLoading}
                                            />
                                            <AppButton
                                                title={'Tirar novamente'}
                                                onClick={() => setOpenCam(true)}
                                                icon={<FlipCameraIosIcon fontSize="large"/>}
                                                className="btn-gray"
                                                style={{width: '80%'}}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <SendConfirm status={showDialog} setStatus={setShowDialog}
                                         body={'Deseja realmente enviar a foto?'} handleAction={handleSubmit}/>
                            <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                        corpo={error?.response?.data?.message || "Não foi possível enviar a imagem!"}
                                        botao={"Ok"}/>
                        </Grid>
                    )}
                </>)}
        </div>
    );
}

export default CaptureEngine;
