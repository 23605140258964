import React, {FC, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppButton from '../../AppButton/AppButton';
import {useSendGeoLocation, useSendGoogleLocation} from '../../../hooks/useGeoLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './NoticeLocation.scss';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {StorageKeys} from '../../../enums/StorageKeys';
import {useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {GeoLocation} from '../../../models/GeoLocation';
import DialogInfo from "../../DialogInfo/DialogInfo";

interface NoticeLocationProps {
}

const NoticeLocation: FC<NoticeLocationProps> = () => {
    const [{guid} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const [position, setPosition] = useState<GeoLocation>({} as GeoLocation);
    const {mutate, isError, isLoading, isSuccess} = useSendGeoLocation({coords: position});
    const [showError, setShowError] = useState(false);
    const {data, refetch} = useSendGoogleLocation();
    const navigate = useNavigate();

    const getLocation = () => {
        return navigator.geolocation?.getCurrentPosition((x: GeolocationPosition) => {
                setPosition({
                    latitude: x.coords.latitude,
                    longitude: x.coords.longitude,
                    accuracy: x.coords.accuracy,
                    guid: guid,
                });
            },
            () => {
                refetch().then(r => {
                    (r?.status === "error") && setShowError(true);
                });
            });
    };

    useEffect(() => {
        isSuccess && navigate('/notice/camera');
    }, [isSuccess, isError]);

    useEffect(() => {
        position && mutate({coords: position});
    }, [position]);

    useEffect(() => {
        data && setPosition({
            latitude: data.location.lat,
            longitude: data.location.lng,
            accuracy: data.accuracy,
            guid: guid,
        });
    }, [data]);

    return (
        <Grid container className="NoticeLocation" data-testid="NoticeLocation">
            <Grid item xs={12} marginTop="40px">
                <LocationOnIcon fontSize="large" className="icon"/>
                <Typography variant="subtitle1">Precisamos da sua<br/>localização</Typography>
                <Typography variant="body1">É muito importante você manter a sua localização ativa, para validarmos se
                    realmente é você.</Typography>
                {isLoading && <CircularProgress style={{marginTop: '40px'}} color="error"/>}
            </Grid>

            <Grid item xs={12} alignSelf="flex-start" marginTop="30px">
                <AppButton
                    title="Permitir Localização"
                    onClick={() => getLocation()}
                />
                <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                            corpo={"Não foi possível obter sua localização, por favor, tente novamente."}
                            botao={"Ok"}/>
            </Grid>
        </Grid>
    );
}

export default NoticeLocation;
