import {FC, useEffect, useRef, useState} from 'react';
import {ArrowRight} from '@mui/icons-material';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {videoContractRead} from '../../models/StepsSendRules';
import {useCamera} from '../../providers/CameraProvider';
import AppButton from '../AppButton/AppButton';
import DialogInfo from '../DialogInfo/DialogInfo';
import ListComponent from '../ListComponent/ListComponent';
import SendConfirm from '../SendConfirm/SendConfirm';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import VideoComponent from '../VideoComponent/VideoComponent';
import ReactPlayer from 'react-player/file';
import './CaptureVideo.scss';
import {useStoreChecklist} from "../../hooks/useChecklist";
import {StoreChecklist} from "../../models/StoreChecklist";

interface CaptureVideoProps {
}

const CaptureVideo: FC<CaptureVideoProps> = () => {
    const [payload, setPayload] = useState<StoreChecklist>({} as StoreChecklist);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showMsgVertical, setShowMsgVertical] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [erroOrientacao, setErroOrientacao] = useState(false);
    const [erroMsg, setErroMsg] = useState('');
    const {item} = useParams<string>();
    const [guid] = useLocalStorage(StorageKeys.GUID, '');

    const {mutate, isError, isSuccess, isLoading: isLoadingSave, error} = useStoreChecklist(payload);
    const {
        source,
        openCam,
        setOpenCam,
        resetCam,
        stopCam,
        setRecordedChunks,
        setSource,
        setFileUpload,
        fileUpload
    } = useCamera();

    const navigate = useNavigate();

    const handleSubmit = async () => {
        mutate(payload);
    }

    const confirmSend = () => {
        setShowDialog(true);
    }

    const landscapeError = async () => {
        if (canTakePicture()) {
            setRecordedChunks([]);
            setErroOrientacao(false);
            setOpenCam(true);
            setIsLoading(false);
        } else {
            setShowMsgVertical(true);
        }
    }

    useEffect(() => {
        if (!canTakePicture()) {
            setErroOrientacao(true);
            setErroMsg('A captura só pode ser feita com o celular na vertical.<br />Posicione o celular na vertical e clique no botão abaixo');
        }
    });

    const canTakePicture = (): boolean => {
        if (window.innerWidth >= 763) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        fileUpload && setPayload({
            guid: guid,
            fileUpload: fileUpload || undefined,
            item: +(item || '0')
        });
    }, [fileUpload]);

    useEffect(() => {
        stopCam();
        resetCam();
        setSource('');
        setFileUpload(null);
        setRecordedChunks([]);
    }, []);

    useEffect(() => {
        isSuccess && navigate('/checklist');
        isError && setShowError(true);
    }, [isSuccess, isError]);

    return (
        <div className="CaptureVideo" data-testid="CaptureVideo">
            {isLoading && (
                <Grid
                    item
                    xs={12}
                    alignItems="center"
                    flex={1}
                    flexDirection="row"
                    marginY="100px"
                >
                    <CircularProgress color="error"/>
                </Grid>
            )}

            {!openCam && !isLoading && (
                <Grid
                    item
                    xs={12}
                    marginTop="20px"
                    className="content-body"
                >
                    {!source && (<Typography variant="h4">Siga as instruções</Typography>)}
                    <br/>
                    {source ? (
                        <Typography variant="subtitle1" marginTop="20px">Verifique se o vídeo está nítido,<br/>caso
                            precise, você pode fazer um novo vídeo.</Typography>
                    ) : (
                        <>
                            <Box
                                justifyContent="center"
                                flexDirection="column"
                                className="img-box"
                                marginTop="20px"
                            >
                                <Typography variant="h6">Se atente aos cuidados<br/>abaixo para fazer o vídeo de
                                    confirmação do seu contrato:</Typography>

                                <ListComponent data={videoContractRead}/>
                            </Box>
                        </>
                    )}
                </Grid>
            )}

            <Grid
                item
                xs={12}
                marginTop="5px"
                className="content-body"
            >
                {!openCam && source && !isLoading && !isLoadingSave && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        className="img-box preview"
                    >
                        <>
                            <ReactPlayer
                                playing={false}
                                url={source}
                                height='500px'
                                width='300px'
                                controls
                            />
                        </>
                    </Box>
                )}

                {isLoadingSave &&
                    <Grid
                        item
                        xs={12}
                        marginY='50%'
                    >
                        <CircularProgress/>
                    </Grid>
                }
            </Grid>

            {!isLoading && (
                <Grid
                    item
                    xs={12}
                    marginTop="30px"
                    className="content-body"
                >
                    {openCam ? (
                        <>
                            {!erroOrientacao ? (
                                <VideoComponent
                                    mode={'user'}
                                    overlay={true}
                                />
                            ) : (
                                <>
                                    <Typography variant="subtitle1"><span
                                        dangerouslySetInnerHTML={{__html: erroMsg}}></span></Typography>
                                    <br/>
                                    <AppButton
                                        title={'Iniciar Câmera'}
                                        onClick={() => landscapeError()}
                                        icon={<FlipCameraIosIcon fontSize="large"/>}
                                        style={{width: '80%'}}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {!source ? (
                                <AppButton
                                    title={`Fazer vídeo`}
                                    onClick={() => {
                                        setOpenCam(true);
                                    }}
                                    icon={<ArrowRight fontSize="large"/>}
                                />
                            ) : (
                                <>
                                    <AppButton
                                        title={'Enviar'}
                                        onClick={() => confirmSend()}
                                        icon={<FileUploadIcon fontSize="large"/>}
                                        disabled={isLoadingSave}
                                        style={{width: '80%'}}
                                    />

                                    <AppButton
                                        title={'Tirar novamente'}
                                        onClick={() => landscapeError()}
                                        icon={<FlipCameraIosIcon fontSize="large"/>}
                                        className="btn-gray"
                                        style={{width: '80%'}}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <SendConfirm status={showDialog} setStatus={setShowDialog}
                                 body={'Deseja realmente enviar a foto?'} handleAction={handleSubmit}/>
                    <DialogInfo show={showMsgVertical} setShow={setShowMsgVertical} titulo={"Atenção"}
                                corpo={"Posicione o celular na vertical para continuar!"} botao={"Ok"}/>
                    <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                corpo={error?.response?.data?.message || "Não foi possível enviar o vídeo!"}
                                botao={"Ok"}/>
                </Grid>
            )
            }
        </div>
    );
}

export default CaptureVideo;
