import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

interface ContextProps {
    source: string,
    setSource: Dispatch<SetStateAction<string>>,
    sourceEncrypted: string,
    setSourceEncrypted: Dispatch<SetStateAction<string>>,
    openCam: boolean,
    setOpenCam: Dispatch<SetStateAction<boolean>>,
    enableStream: () => void,
    resetCam: () => void,
    stopCam: () => void,
    isAndroid: boolean,
    isIos: boolean,
    recordedChunks: any[], 
    setRecordedChunks: Dispatch<SetStateAction<any[]>>,
    fileUpload: Blob|null, 
    setFileUpload: Dispatch<SetStateAction<Blob|null>>,
}

export const CameraContext = React.createContext<ContextProps>({} as ContextProps);

interface CameraProviderProps {
    children: ReactNode,
}

export const CameraProvider = ({children}: CameraProviderProps) => {
    const [source, setSource] = useState<string>('');
    const [sourceEncrypted, setSourceEncrypted] = useState<string>('');
    const [openCam, setOpenCam] = useState<boolean>(false);
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
    const [isAndroid, setIsAndroid] = useState<boolean>(false);
    const [isIos, setIsIos] = useState<boolean>(false);
    const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
    const [fileUpload, setFileUpload] = useState<Blob|null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        setIsAndroid(navigator.userAgent.includes('Android'));
        setIsIos(navigator.userAgent.includes('iPhone'));
    }, []);

    const enableStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true});
            setMediaStream(stream);
            stream.getTracks().forEach(x => x.stop());
            navigate('/phone-validation');
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                return navigate('/phone-validation');
            }
            navigate('/permissions');
        }
    }

    const stopCam = () => {
        mediaStream?.getTracks().forEach(x => x.stop());
    }

    const resetCam = () => {
        setOpenCam(false);
        setSource('');
        setSourceEncrypted('');
    }

    const scrollIt = () => {
        window.scrollTo(0, 1);
    };

    useEffect(() => {
        if (openCam) {
            scrollIt();
        }
    }, [openCam])

    return (
        <CameraContext.Provider value={{
            source,
            setSource,
            sourceEncrypted,
            setSourceEncrypted,
            openCam,
            setOpenCam,
            enableStream,
            resetCam,
            stopCam,
            isAndroid,
            isIos,
            recordedChunks, 
            setRecordedChunks,
            fileUpload, 
            setFileUpload
        }}>
            {children}
        </CameraContext.Provider>
    );
}

export const useCamera = () => React.useContext(CameraContext);
