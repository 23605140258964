import {FC, useCallback, useEffect, useState} from 'react';
import {FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import './NoticeDebts.scss';
import AppButton from '../../AppButton/AppButton';
import DialogInfo from '../../DialogInfo/DialogInfo';
import {useSendTermo} from '../../../hooks/useTermo';
import {Termo} from '../../../models/Termo';
import {useStyle} from '../../../providers/StyleProvider';
import TermoService from '../../../services/TermoService';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {StorageKeys} from '../../../enums/StorageKeys';
import {useNavigate} from 'react-router-dom';
import SendConfirm from '../../SendConfirm/SendConfirm';
import CircularProgress from "@mui/material/CircularProgress";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface NoticeDebtsProps {
}

const NoticeDebts: FC<NoticeDebtsProps> = () => {
    const [isOption, setIsOption] = useState<boolean | null>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [termo, setTermo] = useState<Termo>({} as Termo);
    const [user,] = useLocalStorage(StorageKeys.USER, {});
    const [termosStorage, setTermosStorage] = useLocalStorage(StorageKeys.TERMOS, {});
    const {mutate, isError, isLoading, isSuccess, error} = useSendTermo({termo});
    const [isLoadingIp, setIsLoadingIp] = useState<boolean>(false);
    const {corPrincipal} = useStyle();
    const navigate = useNavigate();

    const handleChange = (event: any) => {
        setIsOption(event?.target?.value === 's');
    };

    const handleSubmit = async () => {
        const obj = {...termosStorage, debitosQuitados: isOption};

        await setTermosStorage(obj);

        mutate({termo});
    }

    useEffect(() => {
        if (isSuccess) {
            navigate('/notice/location');
        }

        isError && setShowError(true);

    }, [isSuccess, isError]);

    useEffect(() => {
        if (isOption !== null) {
            setTermo({...termo, ...{data: {...termosStorage, debitosQuitados: isOption}}})
        }
    }, [isOption]);

    const getPost = useCallback(async () => {
        setIsLoadingIp(true);
        let response = await TermoService.getIpLocal();
        let attempts = 0;

        while (response?.code !== 200) {
            response = await TermoService.getIpLocal();
            attempts++;
            if (attempts === 3 && response?.code !== 200) {
                response.code = 200;
                response.data.ip = '1.1.1.1';
            }
        }
        setIsLoadingIp(false);

        setTermo({
            ip: response?.data?.ip,
            deviceId: navigator.userAgent,
            cpf: user.cpf,
            data: {debitosQuitados: isOption},
            guid: user.guid
        });
    }, []);

    useEffect(() => {
        getPost();
    }, []);

    return (
        <Grid container className="NoticeDebts" data-testid="NoticeDebts">
            {!isLoadingIp ? (
                    <>
                        <Grid item xs={12}>
                            <InfoIcon fontSize="large" className="icon"/>
                            <Typography variant="subtitle1">Aviso</Typography>
                            <Typography variant="body1" mt={3}>
                                O Sr.(a) está realizando negociações com o correspondente bancários para quitação de outras
                                operações financeiras com o recurso que será disponibilizado?
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={3}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value="s"
                                        control={
                                            <Radio
                                                onChange={handleChange}
                                                sx={{
                                                    color: `${corPrincipal}`,
                                                    '&.Mui-checked': {
                                                        color: `${corPrincipal}`,
                                                    }
                                                }}
                                            />
                                        }
                                        label="Sim"
                                    />
                                    <FormControlLabel
                                        value="n"
                                        control={
                                            <Radio
                                                onChange={handleChange}
                                                sx={{
                                                    color: `${corPrincipal}`,
                                                    '&.Mui-checked': {
                                                        color: `${corPrincipal}`,
                                                    }
                                                }}
                                            />
                                        }
                                        label="Não"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} alignSelf="flex-start" marginTop="30px">
                            <AppButton
                                title="Continuar"
                                onClick={() => setShowDialog(true)}
                                disabled={isLoading || isOption === null}
                                isLoading={isLoading}
                            />
                        </Grid>

                        <SendConfirm status={showDialog} setStatus={setShowDialog} body={'Deseja realmente continuar?'}
                                     handleAction={handleSubmit}/>
                        <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                    corpo={error?.response?.data?.message || "Ocorreu um erro ao continuar, tente novamente!"}
                                    botao={"Ok"}/>
                    </>)
                : (<Grid item xs={12}>
                    <CircularProgress/>
                </Grid>)}
        </Grid>
    );
}

export default NoticeDebts;
