import React, {FC, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CircularProgress from '@mui/material/CircularProgress';
import ChecklistItens from '../../components/ChecklistItens/ChecklistItens';
import MasterPage from '../MasterPage/MasterPage';
import AppButton from '../../components/AppButton/AppButton';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import {useGetChecklist} from '../../hooks/useChecklist';
import {CheckListItem} from '../../models/CheckListItem';
import {Alert} from '@mui/material';
import './Checklist.scss';
import {Clients} from '../../enums/Clients';
import {useStyle} from '../../providers/StyleProvider';

interface StepsProps {
}

const Checklist: FC<StepsProps> = () => {
    const [guid] = useLocalStorage(StorageKeys.GUID, '');
    const {data, isLoading, error, refetch} = useGetChecklist(guid);
    const [items, setItems] = useState<CheckListItem[]>([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const navigate = useNavigate();
    const handleRefresh = async () => refetch();
    const {sigla} = useStyle();

    useEffect(() => {
        if (data && data?.produto?.checklist?.length > 0) {
            setItems(data.produto.checklist);
            setIsAllChecked(data.produto.checklist.every((item: CheckListItem) => item.checked));
        }
    }, [data]);

    const switchComponent = () => {
        if (isLoading) {
            return <Grid
                item
                xs={12}
                alignItems="center"
                flex={1}
                flexDirection="row"
                marginY="100px"
            >
                <CircularProgress color="error"/>
            </Grid>;
        }

        if (error) {
            return <Grid
                item
                xs={12}
                alignItems="center"
                flex={1}
                flexDirection="row"
                marginY="100px"
            >
                <Alert severity="warning" style={{'textAlign': 'start'}}>{error.message}</Alert>
            </Grid>;
        }

        return <Grid item xs={12} height={'100%'}>
            <PullToRefresh onRefresh={handleRefresh}>
                <>
                    <ChecklistItens items={items}/>
                    <AppButton
                        onClick={() => navigate('/contract-review')}
                        disabled={!isAllChecked} title="Continuar"
                    />
                </>
            </PullToRefresh>
        </Grid>;
    }

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="Checklist"
                data-testid="Checklist"
            >
                {switchComponent()}
            </Grid>
        </MasterPage>
    );
}

export default Checklist;
