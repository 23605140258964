import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';

interface ContextProps {
    source: File | null;
    setSource: Dispatch<SetStateAction<File | null>>,
    base64: string,
    setBase64: Dispatch<SetStateAction<string>>,
}

export const FileContext = React.createContext<ContextProps>({} as ContextProps);

interface FileProviderProps {
    children: ReactNode,
}

export const FileProvider = ({children}: FileProviderProps) => {
    const [source, setSource] = useState<File | null>(null);
    const [base64, setBase64] = useState<string>('');

    const toBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    useEffect(() => {
        source && toBase64(source).then(async (file: any) => console.log(await toBase64(file)));
    }, [source]);

    return (
        <FileContext.Provider value={{
            source,
            setSource,
            base64,
            setBase64,
        }}>
            {children}
        </FileContext.Provider>
    );
}

export const useFile = () => React.useContext(FileContext);
