import { FC } from 'react';
import './Timer.scss';

interface TimerProps {
  time: number;
}

const Timer: FC<TimerProps> = ({ time }) => (
  <div className="Timer" data-testid="Timer">
    <span className="digits">
        {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
      </span>
      <span className="digits">
        {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
      </span>
  </div>
);

export default Timer;
