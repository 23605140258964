import axios from 'axios';
import {Termo} from '../models/Termo';
import api from './Api';

const TermoService = {
    async send(termo: Termo) {
        const {data: {data}} = await api.post(`api/formalizacao/termos/aceite`, termo);
        return data;
    },

    //creating function to load ip address from the API
    async getIp() {
        const {data} = await axios.get('http://geolocation-db.com/json/');
        return data;
    },

    async getIpLocal() {
        const {data} = await api.get('api/formalizacao/ipaddress');
        return data;
    }
}

export default TermoService;