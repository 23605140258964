export enum CheckListItems {
    DOC_IDENTIDADE = 'identification-document',
    SELFIE = 'selfie-validation',
    MOTOR_VEICULO = 'engine-shipping',
    TRASEIRA_VEICULO = 'license-plate',
    DOC_VEICULO = 'vehicle-document',
    CHILDREN_FOTO_DOC = 'identification-document-welcome',
    CHILDREN_CNH = 'identification-cnh',
    CHILDREN_RG = 'identification-rg-welcome',
    CHILDREN_RG_FRENTE = 'identification-rg-front',
    CHILDREN_RG_VERSO = 'identification-rg-verse',
    ENERGY_BILL = 'energy-bill',
}