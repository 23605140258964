import {useMutation} from 'react-query';
import SmsService from '../services/SmsService';
import {ResponseData} from '../models/ResponseData';
import {AxiosError} from 'axios';

interface ISMSParams {
    guid?: string;
}

interface ISMSVerifyParams {
    guid: string;
    code: string;
}

export const useSendResendSMS = ({guid}: ISMSParams) => useMutation<any, AxiosError<ResponseData>, ISMSParams>(
    'send-sms-validar-celular',
    () => SmsService.sendResend(guid || ''),
    {
        onError: (error: AxiosError) => error.response?.data,
    },
);

export const useVerifySMS = (
    {
        guid,
        code,
    }: ISMSVerifyParams) => useMutation<any, AxiosError<ResponseData>, ISMSVerifyParams>(
    'verify-sms-validar-celular',
    () => SmsService.verify(guid, code),
    {
        onError: (error: AxiosError) => error.response?.data,
    },
);
