import React, {FC, useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MasterPage from '../MasterPage/MasterPage';

import './ContractReview.scss';
import Box from '@mui/material/Box';
import AppButton from '../../components/AppButton/AppButton';
import ArrowRight from '@mui/icons-material/ArrowRight';
import {useNavigate} from 'react-router-dom';

import {useConfirmContract, useGetResume} from "../../hooks/useChecklist";
import useLocalStorage from "../../hooks/useLocalStorage";
import {StorageKeys} from "../../enums/StorageKeys";
import {Resume} from "../../models/Resume";
import FormataMoeda from "../../utils/FormataMoeda";
import moment from 'moment';
import {Alert} from "@mui/material";
import SendConfirm from "../../components/SendConfirm/SendConfirm";

interface ContractReviewProps {
}

const ContractReview: FC<ContractReviewProps> = () => {

    const [guid] = useLocalStorage(StorageKeys.GUID, '');
    const [showDialog, setShowDialog] = useState(false);
    const {mutate, isSuccess, isError: isErrorConfirm, error, isLoading} = useConfirmContract(guid);
    const [resume, setResume] = useState<Resume>({} as Resume);
    const [user,] = useLocalStorage(StorageKeys.USER, {});
    const navigate = useNavigate();

    useGetResume(guid || '', (data: Resume) => {
        setResume(data);
    });

    const confirmSend = () => {
        setShowDialog(true);
    }

    const handleSubmit = async () => {
        mutate(guid);
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 500);
    // }, []);

    useEffect(() => {
        if (isSuccess) {
            navigate('/success');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (user?.status !== 1) {
            navigate('/');
        }
    }, [user]);

    return (
        <MasterPage>
            {isErrorConfirm && (<Alert severity="error" style={{marginTop: '10px'}}>
                {error?.response?.data?.message || ''}
            </Alert>)}
            <Grid
                item
                xs={12}
                alignItems="center"
                className="ContractReview"
                data-testid="ContractReview"
            >
                {isLoading ? (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress/>

                    </Grid>
                ) : (
                    <Grid
                        item
                        xs={12}
                        marginTop="5px"
                        className="content-body"
                    >
                        <Typography variant="h4">Revise os dados da
                            proposta<br/><span>#{resume.propostaId}</span></Typography>

                        <Typography variant="body1" marginTop="10px">Confira se os valores abaixo estão de acordo com os
                            apresentados no momento da sua contratação.</Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            className="box-bg"
                        >
                            {!!resume?.valorLiberado && (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography variant="body1">Valor solicitado</Typography>

                                    <Typography variant="body2">{FormataMoeda(resume.valorLiberado)}</Typography>
                                </Grid>
                            )}

                            {!!resume?.valorFinanciado && (
                                <Grid
                                    item

                                    xs={12}
                                    marginTop="15px"
                                >
                                    <Typography variant="body1">Valor a ser financiado</Typography>
                                    <Typography variant="body2">{FormataMoeda(resume.valorFinanciado)}</Typography>
                                </Grid>
                            )}

                            {(!!resume?.valorParcela || !!resume?.parcelas) && (
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="15px"
                                    display="flex"
                                    flexDirection="row"
                                >
                                    {!!resume?.valorParcela && (
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Valor por parcela</Typography>
                                            <Typography variant="body2">{FormataMoeda(resume.valorParcela)}</Typography>
                                        </Grid>
                                    )}

                                    {!!resume?.parcelas && (
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Qtd. parcelas</Typography>
                                            <Typography variant="body2">{resume.parcelas}x</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            )}

                            {!!resume?.vencimento && (
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="15px"
                                >
                                    <Typography variant="body1">1ª parcela em</Typography>
                                    <Typography
                                        variant="body2">{moment(resume.vencimento).format('DD/MM/YYYY')}</Typography>
                                </Grid>
                            )}

                            {!!resume?.produto && (
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="15px"
                                >
                                    <Typography variant="body1">Tipo do produto</Typography>
                                    <Typography variant="body2">{resume.produto}</Typography>
                                </Grid>
                            )}

                            {!!resume?.valorIOF && (
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="15px"
                                >
                                    <Typography variant="body1">IOF</Typography>
                                    <Typography variant="body2">{FormataMoeda(resume.valorIOF)}</Typography>
                                </Grid>
                            )}

                            {(!!resume?.taxa || !!resume?.CET) && (
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="15px"
                                    display="flex"
                                    flexDirection="row"
                                >
                                    {!!resume?.taxa && (
                                        <Grid
                                            item
                                            xs={6}
                                        >
                                            <Typography variant="body1">Taxa</Typography>
                                            <Typography variant="body2">{resume.taxa}% <span>a.m</span></Typography>
                                        </Grid>
                                    )}

                                    {!!resume?.CET && (
                                        <Grid
                                            item
                                            xs={6}
                                        >
                                            <Typography variant="body1">CET</Typography>
                                            <Typography variant="body2">{resume.CET}<span>% a.m</span></Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Box>
                        <AppButton
                            title={'Finalizar'}
                            icon={<ArrowRight fontSize='large'/>}
                            onClick={confirmSend}
                        />
                    </Grid>
                )}
                <SendConfirm status={showDialog} setStatus={setShowDialog} body={'Deseja realmente finalizar?'}
                             handleAction={handleSubmit}/>
            </Grid>
        </MasterPage>
    );
}

export default ContractReview;
