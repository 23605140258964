import React, {FC, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import './MasterPage.scss';
import CheckFinished from '../../components/CheckFinished/CheckFinished';
import {useStyle} from "../../providers/StyleProvider";

interface MasterPageProps extends React.HTMLAttributes<HTMLDivElement> {

}

const MasterPage: FC<MasterPageProps> = ({children}) => {
    const {logoPrincipal, client, updateInfoIndex} = useStyle();

    useEffect(() => {
        updateInfoIndex();
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            className="MasterPage"
            data-testid="MasterPage"
            height={'100vh'}
        >
            <Grid item xs={12} alignSelf="flex-start">
                {!!logoPrincipal && <img src={logoPrincipal} className="app-logo" alt={client}/>}
            </Grid>
            {/* <CheckFinished /> */}

            {children}
        </Grid>
    )
};

export default MasterPage;
