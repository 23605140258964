import React, {Dispatch, ReactNode, SetStateAction, useContext, useState} from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import {StorageKeys} from '../enums/StorageKeys';
import {useGetClientStyle} from '../hooks/useChecklist';
import {ClientStyle} from '../models/ClientStyle';

interface ContextProps {
    corPrincipal: string,
    logoPrincipal: string,
    logoBranco: string,
    client: string,
    produto: string,
    sigla: string,
    updateInfoIndex: () => void;
    setProduto: Dispatch<SetStateAction<string>>,
    setClient: Dispatch<SetStateAction<string>>,
    setLogoBranco: Dispatch<SetStateAction<string>>,
    setLogoPrincipal: Dispatch<SetStateAction<string>>,
    setCorPrincipal: Dispatch<SetStateAction<string>>,
    setSigla: Dispatch<SetStateAction<string>>
}

export const StyleContext = React.createContext<ContextProps>({} as ContextProps);

interface StyleProviderProps {
    children: ReactNode,
}

export const StyleProvider = ({children}: StyleProviderProps) => {
    const [corPrincipal, setCorPrincipal] = useState<string>('');
    const [corSecundaria, setCorSecundaria] = useState<string>('');
    const [logoPrincipal, setLogoPrincipal] = useState<string>('');
    const [logoBranco, setLogoBranco] = useState<string>('');
    const [client, setClient] = useState<string>('');
    const [produto, setProduto] = useState<string>('');
    const [sigla, setSigla] = useState<string>('');
    const [guid] = useLocalStorage(StorageKeys.GUID, '');

    const {isLoading: isLoadingStyle} = useGetClientStyle(guid || '', (data: ClientStyle) => {
        setProduto(data.produto);
        setClient(data.client);
        setLogoBranco(data.logoSecundaria);
        setLogoPrincipal(data.logo);
        setCorPrincipal(data.cor);
        setSigla(data.sigla);
        setCorSecundaria(data.corSecundaria);
    });

    const updateElement = (element: Element, content: string) => {
        element.setAttribute('content', content);
    };

    const updateInfoIndex = () => {

        const root = document.documentElement;
        root.style.setProperty('--progress-circle', corPrincipal);
        root.style.setProperty('--primary', corPrincipal);
        root.style.setProperty('--secondary', corSecundaria);

        let element = document.querySelector('meta[name=\'theme-color\']');
        element && updateElement(element, corPrincipal);

        element = document.querySelector('meta[name=\'apple-mobile-web-app-status-bar-style\']');
        element && updateElement(element, corPrincipal);

        element = document.querySelector('meta[name=\'msapplication-navbutton-color\']');
        element && updateElement(element, corPrincipal);

        element = document.querySelector('meta[name=\'msapplication-TileColor\']');
        element && updateElement(element, corPrincipal);

        element = document.querySelector('meta[name=\'description\']');
        element && updateElement(element, `${client} - Formalização`);

        if (!!client) {
            document.title = `Formalização Digital - ${client}`;
            document.getElementById('favicon')?.setAttribute('href', logoPrincipal || '');
        }
    };

    return (
        !isLoadingStyle ? (
            <StyleContext.Provider value={{
                corPrincipal,
                logoBranco,
                logoPrincipal,
                client,
                produto,
                sigla,
                updateInfoIndex,
                setProduto,
                setClient,
                setLogoBranco,
                setLogoPrincipal,
                setCorPrincipal,
                setSigla
            }}>
                {children}
            </StyleContext.Provider>) : <></>
    );
};

export const useStyle = () => useContext(StyleContext);
