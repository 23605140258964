import { FC } from 'react';
import { Grid } from '@mui/material';
import CaptureVideo from '../../components/CaptureVideo/CaptureVideo';
import Instruction from '../../components/Instruction/Instruction';
import MasterPage from '../MasterPage/MasterPage';
import './ContractRead.scss';

interface ContractReadProps { }

const ContractRead: FC<ContractReadProps> = () => {
  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="ContractRead"
        data-testid="ContractRead"
      >
        <Instruction>
          <CaptureVideo />
        </Instruction>
      </Grid>
    </MasterPage>
  );
}

export default ContractRead;
