import {CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import AppButton from '../../components/AppButton/AppButton';
import DialogInfo from '../../components/DialogInfo/DialogInfo';
import {StorageKeys} from '../../enums/StorageKeys';
import useLocalStorage from '../../hooks/useLocalStorage';
import {Banco} from '../../models/Banco';
import {DadosBancarios} from '../../models/DadosBancarios';
import {BancoService} from '../../services/BancoService';
import MasterPage from '../MasterPage/MasterPage';
import './UpdateBankInfo.scss';

interface UpdateBankInfoProps {
}

const UpdateBankInfo: FC<UpdateBankInfoProps> = () => {
    const [guid] = useLocalStorage(StorageKeys.GUID, {});
    const [{produto} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const service = new BancoService();
    const [dadosBancarios, setDadosBancarios] = useState<DadosBancarios>(new DadosBancarios());
    const [bancos, setBancos] = useState<Banco[]>([]);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [bancoSelecionado, setBancoSelecionado] = useState<string>('');
    const [tipoContaSelecionada, setTipoContaSelecionada] = useState<string>('');
    const [erro, setErro] = useState<string>('');
    const navigate = useNavigate();
    const {handleSubmit, formState: {errors}, register} = useForm<DadosBancarios>();

    const getDadosBancarios = async () => {
        const [response, error] = await service.getDadosBancarios(guid);
        if (error?.length > 0) {
            let erros = '';
            error.forEach((item: any, index: number) => {
                erros += index === 0 ? item : ` | ${item}`;
            });
            setErro(erros);
            setShowInfo(true);
        } else {
            setDadosBancarios(response);
        }
    }

    const getBancos = async () => {
        const [response, error] = await service.getBancos(produto?.financeira?.sigla);
        if (error?.length > 0) {
            let erros = '';
            error.forEach((item: any, index: number) => {
                erros += index === 0 ? item : ` <br> ${item}`;
            });
            setErro(erros);
            setShowInfo(true);
        } else {
            setBancos(response);
        }
    }

    useEffect(() => {
        if(produto) {
            getBancos();
        }
    }, [produto]);

    useEffect(() => {
        if(guid) {
            getDadosBancarios();
        }
    }, [guid]);

    useEffect(() => {
        erro && setShowInfo(true);
    }, [erro]);

    useEffect(() => {
        !showInfo && setErro('');
    }, [showInfo]);

    const onSubmit = async (data: DadosBancarios) => {
        data.agenciaDigito === '' && (data.agenciaDigito = '0');
        data.contaDigito === '' && (data.contaDigito = '0');
        if (
            ('000' + data.banco).slice(-3) === dadosBancarios.banco &&
            data.contaTipo === dadosBancarios.contaTipo &&
            data.agencia === dadosBancarios.agencia &&
            data.agenciaDigito === dadosBancarios.agenciaDigito &&
            data.conta === dadosBancarios.conta &&
            data.contaDigito === dadosBancarios.contaDigito
        ) {
            setErro('Informe uma conta diferente da exibida em destaque');
        } else {
            if (isNaN(Number(data.agencia)) || Number(data.agencia) === 0 || Number(data.agencia) === 1) {
                setErro('Não é permitido utilizar uma conta digital');
            } else {
                setIsSaving(true);
                const [_response, error] = await service.fetchDados(data, guid);
                setIsSaving(false);
                if (error) {
                    if (error instanceof Array) {
                        let erros = '';
                        error.forEach((item: any, index: number) => {
                            erros += index === 0 ? `* ${item}` : ` <br> * ${item}`;
                        });
                        setErro(erros);
                    } else {
                        setErro(error);
                    }
                } else {
                    navigate(`/f/${guid}`);
                }
            }
        }
    }

    return (
        <div className="UpdateBankInfo" data-testid="UpdateBankInfo">
            <MasterPage>
                {isSaving ?
                    (<>
                        <Grid
                            item
                            alignItems="center"
                        >
                            <CircularProgress/>
                        </Grid>
                    </>)

                    :

                    (<>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>Os dados bancários informados na proposta são
                                inválidos. <br/>
                                Informe um novo <b>Banco, Agência e Conta</b> para continuar com a
                                formalização.</Typography>
                            <br/>
                            <Box className='bg-box'>
                                <Typography variant='subtitle2'><b>Dados recusados</b> <br/>
                                    <b>Banco:</b> {bancos?.find(x => ('000' + x.banco_id).slice(-3) === dadosBancarios?.banco)?.nome}
                                    <br/>
                                    <b>Tipo:</b> {dadosBancarios?.contaTipo === 'PP' ? 'CONTA POUPANÇA' : 'CONTA CORRENTE'}
                                    <br/>
                                    <b>Agência:</b> {dadosBancarios?.agencia}-{dadosBancarios?.agenciaDigito} <br/>
                                    <b>Conta:</b> {dadosBancarios?.conta}-{dadosBancarios?.contaDigito}
                                </Typography>
                            </Box>

                            <br/>
                            <Typography variant='subtitle2'><b>Obs.:</b> Não é permitido utilizar contas de bancos
                                digitais e você deve ser
                                o titular da conta.</Typography>
                        </Grid>
                        <Grid item xs={12} textAlign='left'>
                            <FormControl fullWidth>
                                <InputLabel id="lBancos">*Banco</InputLabel>
                                <Select
                                    labelId="bBancos"
                                    id="banco"
                                    label="*Banco"
                                    {...register('banco')}
                                    value={bancoSelecionado}
                                    defaultValue={''}
                                    onChange={event => setBancoSelecionado(event.target.value)}
                                >
                                    {bancos?.map(x => (
                                        <MenuItem key={x.banco_id}
                                                  value={('0000' + x.banco_id).slice(-3)}>{x.nome}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <br/><br/>

                            <FormControl fullWidth>
                                <InputLabel id="lTipoConta">*Tipo da Conta</InputLabel>
                                <Select
                                    labelId="lTipoConta"
                                    id="tipoConta"
                                    label="*Tipo da Conta"
                                    {...register('contaTipo')}
                                    value={tipoContaSelecionada}
                                    defaultValue={''}
                                    onChange={event => setTipoContaSelecionada(event.target.value)}
                                >
                                    <MenuItem value='CC'>Conta Corrente</MenuItem>
                                    <MenuItem value='PP'>Conta Poupança</MenuItem>
                                </Select>
                            </FormControl>

                            <br/><br/>

                            <div style={{display: 'flex'}}>
                                <div style={{width: '75%', margin: '0'}}>
                                    <FormControl fullWidth>
                                        <TextField
                                            error={errors.agencia ? true : false}
                                            id='agencia'
                                            label='*Agência'
                                            type='number'
                                            {...register('agencia', {required: true})}
                                            helperText={errors.agencia?.type === 'required' ? 'Agência é obrigatória' : ''}/>
                                    </FormControl>
                                </div>
                                &nbsp;
                                <div style={{width: '25%', margin: '0'}}>
                                    <TextField
                                        label='Dígito'
                                        id='agenciaDigito'
                                        type='number'
                                        defaultValue={0}
                                        {...register('agenciaDigito', {maxLength: 1})}
                                        helperText={errors.agenciaDigito?.type === 'maxLength' ? 'Informe apenas um dígito' : ''}
                                    />
                                </div>
                            </div>

                            <br/>

                            <div style={{display: 'flex'}}>
                                <div style={{width: '75%', margin: '0'}}>
                                    <FormControl fullWidth>
                                        <TextField
                                            error={errors.conta ? true : false}
                                            label='*Conta'
                                            id='conta'
                                            type='number'
                                            {...register('conta', {required: true})}
                                            helperText={errors.conta?.type === 'required' ? 'Conta é obrigatória' : ''}/>
                                    </FormControl>
                                </div>
                                &nbsp;
                                <div style={{width: '25%', margin: '0'}}>
                                    <TextField
                                        label='Dígito'
                                        id='contaDigito'
                                        type='number'
                                        defaultValue={0}
                                        {...register('contaDigito', {maxLength: 1})}
                                        helperText={errors.contaDigito?.type === 'maxLength' ? 'Informe apenas um dígito' : ''}
                                    />
                                </div>
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <AppButton
                                title='Enviar'
                                onClick={handleSubmit(onSubmit)}/>
                        </Grid>
                    </>)}
                <DialogInfo show={showInfo} botao={'OK'} titulo={'Atenção'} setShow={setShowInfo} corpo={erro}/>
            </MasterPage>
        </div>
    )
};

export default UpdateBankInfo;
