import React, { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import './Success.scss';
import { useStyle } from "../../providers/StyleProvider";
import useLocalStorage from '../../hooks/useLocalStorage';
import { StorageKeys } from '../../enums/StorageKeys';
import { useGetChecklist } from '../../hooks/useChecklist';
import { Checklist } from '../../models/Checklist';
import { useNavigate } from 'react-router-dom';

interface SuccessProps {
}

const Success: FC<SuccessProps> = () => {
    const { client, logoBranco, corPrincipal, updateInfoIndex } = useStyle();
    const [currentGuid,] = useLocalStorage(StorageKeys.GUID, '');
    const [, setUser] = useLocalStorage(StorageKeys.USER, {});
    const { refetch } = useGetChecklist(currentGuid || '', (_data: Checklist) => { });
    const navigate = useNavigate();

    useEffect(() => {
        updateInfoIndex();
    }, []);

    useEffect(() => {
        if (!!currentGuid) {
            refetch().then(x => {
                if (x?.data?.celular) {
                    setUser(x.data);
                    setTimeout(() => navigate(`/`), 4000);
                }
            });
        }
    }, [currentGuid]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="Success"
            data-testid="Success"
            bgcolor={corPrincipal}
        >
            <img src={logoBranco} className="app-logo" alt={client} />

            <img src={`${process.env.PUBLIC_URL}/assets/success.png`} className="img-success" alt="Logo Dinheiro Sim" />

            <Typography variant="body1" marginTop="20px">Proposta formalizada<br />com sucesso</Typography>

            <Typography variant="subtitle1" marginTop="40px">Após a análise dos seus documentos você receberá um e-mail
                e SMS sobre o andamento da sua proposta.</Typography>
        </Grid>
    );
}

export default Success;
