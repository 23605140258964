import React, { FC, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MasterPage from '../MasterPage/MasterPage';
import CaptureLicensePlate from '../../components/CaptureLicensePlate/CaptureLicensePlate';
import Instruction from '../../components/Instruction/Instruction';

interface LicensePlateProps { }

const LicensePlate: FC<LicensePlateProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="LicensePlate"
        data-testid="LicensePlate"
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
            alignItems="center"
            flex={1}
            flexDirection="row"
            marginY="100px"
          >
            <CircularProgress color="error" />
          </Grid>
        ) : (
          <Instruction>
            <CaptureLicensePlate />
          </Instruction>
        )}
      </Grid>
    </MasterPage>
  );
}

export default LicensePlate;
