import React, { FC } from 'react';
import Button from '@mui/material/Button';
import './AppButton.scss';
import { useStyle } from "../../providers/StyleProvider";
import { CircularProgress } from '@mui/material';

interface AppButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    title?: string,
    disabled?: boolean,
    ariaLabel?: string,
    icon?: JSX.Element,
    isLoading?: boolean,
}

const AppButton: FC<AppButtonProps> = ({ title, onClick, disabled, className, ariaLabel, icon, isLoading = false, ...rest }) => {
    const { corPrincipal } = useStyle();

    return (
        <Button
            variant="contained"
            disabled={disabled}
            className={`AppButton ${className}`}
            data-testid="AppButton"
            onClick={onClick}
            aria-label={ariaLabel || ''}
            component="span"
            endIcon={icon}
            style={{ ...rest?.style, backgroundColor: corPrincipal }}
        >
            <>
                {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                ) : (
                    <>{title && (<span dangerouslySetInnerHTML={{ __html: title }}></span>)}</>
                )}
            </>
        </Button>
    );
}

export default AppButton;
