import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import {ResponseData} from '../models/ResponseData';
import TermoService from '../services/TermoService';
import { Termo } from '../models/Termo';

interface Props {
    termo: Termo
}

export const useSendTermo = ({termo}: Props) => {
    return useMutation<any, AxiosError<ResponseData>, Props>(
        'send-termo',
        () => TermoService.send(termo),
        {
            onError: (error: AxiosError) => error.response?.data,
        },
    );
};