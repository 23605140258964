import React, {useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import MasterPage from '../MasterPage/MasterPage';
import ReactInputVerificationCode from 'react-input-verification-code';
import './PhoneValidation.scss';
import Typography from '@mui/material/Typography';
import AppButton from '../../components/AppButton/AppButton';
import {useForm} from 'react-hook-form';
import useInterval from '../../hooks/useInterval';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import FormataCelular from '../../utils/FormataCelular';
import {PhoneValidationStyle} from './PhoneValidation.Style';
import {useSendResendSMS, useVerifySMS} from '../../hooks/useSMSValidaCelular';
import {Alert} from '@mui/material';
import DialogInfo from "../../components/DialogInfo/DialogInfo";

type FormValues = {
    codeSMS: string;
};

interface PhoneValidationProps {
}

const initialValues = {
    counter: 120,
    formValues: {mode: 'onChange', defaultValues: {codeSMS: ''} as FormValues} as Partial<any>,
};

const PhoneValidation = () => {
    const [sms, setSms] = useLocalStorage(StorageKeys.SMS, false);
    const [guid] = useLocalStorage(StorageKeys.GUID, {});
    const [{celular} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const {handleSubmit, setValue, formState: {errors}} = useForm<FormValues>(initialValues.formValues);
    const [counter, setCounter] = useState<number>(initialValues.counter);
    const [disabled, setDisable] = useState<boolean>(true);
    const [codeSMS, setCodeSMS] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const sendSms = useSendResendSMS({guid: guid});
    const {isSuccess, mutate, error, isError, reset} = useVerifySMS({guid: guid, code: codeSMS});
    const navigate = useNavigate();

    const onSubmit = async () => mutate({guid: guid, code: codeSMS});

    const handleSendSMS = async (verify: boolean) => {
        if (verify && !!sms) {
            return false;
        }
        setCounter(initialValues.counter);
        sendSms.mutate({guid: guid});
        setSms(true);
    };

    useEffect(() => {
        if (sendSms?.isError) {
            setShowError(true);
        }
    }, [sendSms?.isError]);

    useEffect(() => {
        handleSendSMS(false).then();
    }, []);

    useEffect(() => {
        isSuccess && navigate('/checklist');
    }, [isSuccess]);

    useEffect(() => {
        codeSMS && setValue('codeSMS', codeSMS);
    }, [codeSMS]);

    // useEffect(() => {
    //     guid && handleSendSMS(true).then();
    // }, [guid]);

    useInterval(() => {
        if (counter > 0) {
            return setCounter((oldValue: number) => oldValue - 1);
        }
        !disabled && setDisable(false);
    }, 1000);

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="PhoneValidation"
                data-testid="PhoneValidation"
            >
                {sendSms.isLoading ? (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress color="error"/>
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            xs={12}
                            marginTop="30px"
                            className="content-body"
                        >
                            <Typography variant="subtitle1">Insira agora o código que foi enviado<br/>para o seu
                                celular
                                via SMS.</Typography>

                            <Typography variant="subtitle1" marginTop="10px">Acabamos de enviar o código<br/>para o
                                número <b>{FormataCelular(celular || '')}</b>.</Typography>

                            <Typography
                                variant="subtitle1"
                                marginTop="10px"
                                marginBottom="30px"
                            >
                                Se ainda não chegou, verifique se o seu celular está com sinal. Se mesmo assim não
                                chegar, solicite novamente que reenviamos o código.
                            </Typography>

                            <div className="codeSms" style={PhoneValidationStyle.codeSms}>
                                <ReactInputVerificationCode
                                    type={'text'}
                                    autoFocus={true}
                                    length={5}
                                    placeholder={''}
                                    onCompleted={setCodeSMS}
                                    onChange={() => reset()}
                                />
                            </div>

                            {errors.codeSMS?.type === 'required' && (
                                <p className="error">Código SMS é obrigatório</p>)}
                            {errors.codeSMS?.type === 'min' && (<p className="error">Deve conter 4 caracteres</p>)}
                            {isError && (<Alert severity="error" style={{marginTop: '10px'}}>
                                {error?.response?.data?.message || ''}
                            </Alert>)}

                            <AppButton
                                title={`(${counter}) Reenviar código`}
                                onClick={() => {
                                    setDisable(true);
                                    handleSendSMS(false).then();
                                }}
                                disabled={counter > 0}
                                className="btn-link"
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            marginTop="20px"
                        >
                            <AppButton
                                title="Continuar"
                                onClick={handleSubmit(onSubmit)}
                                disabled={(codeSMS.length) < 4}
                            />
                        </Grid>
                    </>
                )}

                <DialogInfo
                    show={showError}
                    setShow={setShowError}
                    titulo={"Atenção"}
                    corpo={sendSms?.error?.response?.data?.message || "Não foi possível enviar o SMS! Aguarde e tente novamente."}
                    botao={"Ok"}
                />
            </Grid>
        </MasterPage>
    );
}

export default PhoneValidation;
