import {FC, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {ArrowRight} from '@mui/icons-material';
import {Link, useNavigate} from 'react-router-dom';
import AppButton from '../../components/AppButton/AppButton';

import './Welcome.scss';
import {useStyle} from "../../providers/StyleProvider";
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import {FormalizacaoStatus} from '../../enums/FormalizacaoStatus';
import {Clients} from '../../enums/Clients';
import {isAndroid, isIphone, isIpad} from "devtools-detector";

interface WelcomeProps {
}

const Welcome: FC<WelcomeProps> = () => {
    const [guid,] = useLocalStorage(StorageKeys.GUID, '');
    const [user,] = useLocalStorage(StorageKeys.USER, {});
    const navigate = useNavigate();
    const {corPrincipal, logoBranco, produto, client, sigla, updateInfoIndex} = useStyle();

    useEffect(() => {
        updateInfoIndex();
    }, []);

    useEffect(() => {
        user?.status === FormalizacaoStatus.DADOS_BANCARIOS_INVALIDOS && navigate('/update-bank');
    }, [user]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="Welcome"
            data-testid="Welcome"
            bgcolor={corPrincipal}
        >
            <Grid item xs={12} alignSelf="flex-end">

                {!!logoBranco && <img src={logoBranco} className="app-logo" alt={client}/>}
            </Grid>

            {(isIpad || isIphone || isAndroid) ? (<>

                {guid && user ? (
                    <>
                        {(user?.status === FormalizacaoStatus.ANALISE || user?.status === FormalizacaoStatus.FINALIZADA) ?
                            <>
                                <Grid item xs={12} alignSelf={'flex-start'}>
                                    <Typography variant="body1" marginTop={5}>
                                        {user?.status === FormalizacaoStatus.ANALISE ? 'Proposta em Análise' : 'Proposta finalizada'}
                                    </Typography>

                                    {sigla === 'dinheiro-sim' && (
                                        <>
                                            <Typography variant="subtitle1" marginTop={6}>Acompanhe o andamento da sua
                                                proposta através do nosso aplicativo.</Typography>

                                            <Grid xs={12} marginTop={1}>
                                                <a href='https://apps.apple.com/br/app/dinheiro-sim/id1592733489'
                                                   target='_blank'>
                                                    <img className='logo-store'
                                                         src={`${process.env.PUBLIC_URL}/ico_apple_store_white.png`}/>
                                                </a>
                                                <a href='https://play.google.com/store/apps/details?id=com.dinheirosimapp'
                                                   target='_blank'>
                                                    <img className='logo-store'
                                                         src={`${process.env.PUBLIC_URL}/ico_google_playbranco.png`}/>
                                                </a>
                                            </Grid>
                                        </>)}

                                </Grid>
                            </> :
                            user?.status === FormalizacaoStatus.CANCELADA ?
                                <>
                                    <Typography variant="body1" marginTop={-40}>Formalização Cancelada</Typography>
                                </> :
                                (<>
                                    {sigla !== Clients.BEVIOFICIAL ? (
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Bem vindo a última etapa para<br/>contratação
                                                do
                                                seu <b>{produto}.</b></Typography>
                                            <Typography variant="subtitle1" mt={2}>Agora você deve enviar todas as
                                                informações para analisarmos, e se estiver
                                                tudo certo seu dinheiro estará na sua conta :D</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Bem-vindo a uma das etapas de análise para a
                                                contratação do seu <b>{produto}.</b></Typography>
                                            <Typography variant="subtitle1" mt={2}>
                                                Agora, solicitaremos o envio das documentações e informações necessárias
                                                para validação da sua operação.
                                            </Typography>
                                            <Typography variant="subtitle1" mt={2}>
                                                Tenha em mãos seus documentos pessoais e revise os dados da proposta. Em
                                                breve o valor será disponibilizado em sua conta.
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} alignSelf="flex-start">
                                        <Link to={'/notice/information'}>
                                            <AppButton
                                                title='Começar'
                                                className='btn-white m-0'
                                                icon={<ArrowRight fontSize='large'/>}
                                            />
                                        </Link>
                                    </Grid>
                                </>)}

                    </>) : (<>
                    <Grid item xs={12} alignSelf="flex-start" marginTop={!!logoBranco ? 10 : 0}>
                        <Typography variant="subtitle1"><b>Sistema de Formalização</b></Typography>
                        <Typography variant="subtitle1" mt={2}>Realize o acesso através do link recebido por
                            SMS.</Typography>
                    </Grid>
                </>)}
            </>) : (<>
                <Grid item xs={12} alignSelf="flex-start" marginTop={!!logoBranco ? 10 : 0}>
                    <Typography variant="subtitle1"><b>Sistema de Formalização</b></Typography>
                    <Typography variant="subtitle1" mt={2}>Realize o acesso através de um celular.</Typography>
                </Grid>
            </>)}
        </Grid>
    );
}

export default Welcome;
