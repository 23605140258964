import {useMutation, useQuery} from 'react-query';
import ChecklistService from '../services/ChecklistService';
import {Checklist} from '../models/Checklist';
import {StoreChecklist} from '../models/StoreChecklist';
import {AxiosError} from 'axios';
import {ResponseData} from '../models/ResponseData';
import {GetChecklistItem} from '../models/GetChecklistItem';
import {CheckListItem} from '../models/CheckListItem';
import {ClientStyle} from "../models/ClientStyle";
import {Resume} from "../models/Resume";


export const useGetChecklist = (guid: string, onSuccess?: any) => useQuery<Checklist | null, AxiosError<ResponseData>>(
    'get-checklist',
    () => ChecklistService.getChecklist(guid),
    {
        onSuccess: onSuccess,
        refetchOnWindowFocus: true,
        refetchInterval: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error: AxiosError) => error.response?.data,
    },
);

export const useGetChecklistItem = (item: GetChecklistItem) => useQuery<CheckListItem, AxiosError<ResponseData>, CheckListItem>(
    'get-checklist-item',
    () => ChecklistService.get(item),
    {
        onSuccess: item.onSuccess || null,
        refetchOnWindowFocus: true,
        refetchInterval: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error: AxiosError) => error.response?.data,
    },
);

export const useGetResume = (guid: string, onSuccess?: any) => useQuery<Resume | null, AxiosError<ResponseData>>(
    'get-resume',
    () => ChecklistService.getResume(guid),
    {
        onSuccess: onSuccess,
        refetchOnWindowFocus: true,
        refetchInterval: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error: AxiosError) => error.response?.data,
    }
)

export const useGetClientStyle = (guid: string, onSuccess?: any) => useQuery<ClientStyle | null, AxiosError<ResponseData>>(
    'get-style',
    () => ChecklistService.getClientDetails(guid),
    {
        enabled: !!guid,
        onSuccess: onSuccess,
        refetchOnWindowFocus: true,
        refetchInterval: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error: AxiosError) => error.response?.data,
    }
);

export const useStoreChecklist = (store: StoreChecklist) => useMutation<any, AxiosError<ResponseData>, StoreChecklist>(
    'store-checklist-item',
    () => ChecklistService.storeChecklist(store),
    {
        onError: (error: AxiosError) => error.response?.data,
    },
);

export const useConfirmContract = (guid: string) => useMutation<any, AxiosError<ResponseData>>(
    'confirm-contract',
    () => ChecklistService.postConfirm(guid),
    {
        onError: (error: AxiosError) => error.response?.data,
    },
);