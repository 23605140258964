import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppButton from '../../AppButton/AppButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useCamera } from '../../../providers/CameraProvider';

import './NoticeCamera.scss';

interface NoticeCameraProps { }

const NoticeCamera: FC<NoticeCameraProps> = () => {
  const { enableStream } = useCamera();

  return (
    <Grid container className="NoticeCamera" data-testid="NoticeCamera">
      <Grid item xs={12} marginTop="40px">
        <PhotoCameraIcon fontSize='large' className='icon' />
        <Typography variant="subtitle1">Precisamos usar sua<br/>câmera</Typography>
        <Typography variant="body1">É muito importante você manter o acesso da câmera de seu dispositivo ativa. É obrigatório para realizar os envios de documentos e validarmos se realmente é você.</Typography>
      </Grid>

      <Grid item xs={12} alignSelf="flex-start" marginTop="30px">
        <AppButton
          title='Permitir Câmera'
          onClick={enableStream}
        />
      </Grid>
    </Grid>
  );
}

export default NoticeCamera;
