import {useEffect, useState} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom"
import {FormalizacaoStatus} from "../enums/FormalizacaoStatus";
import {StorageKeys} from "../enums/StorageKeys";
import useLocalStorage from "../hooks/useLocalStorage";
import {isIphone, isAndroid, isIpad} from "devtools-detector";

export const AuthenticationGate = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [user,] = useLocalStorage(StorageKeys.USER, {});
    const location = useLocation();

    useEffect(() => {
        const disposer = () => setTimeout(() => setIsLoading(false), 0);
        disposer();
    }, []);

    if (isLoading) {
        return <></>
    }

    if (!(isIpad || isIphone || isAndroid)) {
        return <Navigate to="/"/>
    }

    if (!user?.cpf) {
        return <Navigate to="/"/>
    }

    if (user?.status === FormalizacaoStatus.ANALISE || user?.status === FormalizacaoStatus.CANCELADA || user?.status === FormalizacaoStatus.FINALIZADA) {
        return <Navigate to="/"/>
    }

    if (user?.status === FormalizacaoStatus.DADOS_BANCARIOS_INVALIDOS && location.pathname !== '/update-bank') {
        return <Navigate to="/update-bank"/>
    }

    return (
        <div>
            <Outlet/>
        </div>
    )
}