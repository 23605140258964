import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { FC, useState } from 'react';
import CaptureFrontDocument from '../../components/CaptureFrontDocument/CaptureFrontDocument';
import Instruction from '../../components/Instruction/Instruction';
import MasterPage from '../MasterPage/MasterPage';

interface IdentificationRgFrontProps { }

const IdentificationRgFront: FC<IdentificationRgFrontProps> = () => {

  const [isLoading,] = useState();

  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="IdentificationRgFront"
        data-testid="IdentificationRgFront"
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
            alignItems="center"
            flex={1}
            flexDirection="row"
            marginY="100px"
          >
            <CircularProgress color="error" />
          </Grid>
        ) : (
          <Instruction>
            <CaptureFrontDocument />
          </Instruction>
        )}
      </Grid>
    </MasterPage>
  );
};

export default IdentificationRgFront;
