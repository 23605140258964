import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { StorageKeys } from '../../enums/StorageKeys';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useCamera } from '../../providers/CameraProvider';
import FormataCpf from '../../utils/FormataCpf';
import Timer from '../Timer/Timer';
import './VideoComponent.scss';

interface VideoComponentProps {
    mode?: string
    overlay?: boolean,
}

const VideoComponent: FC<VideoComponentProps> = ({ overlay, mode = 'environment' }) => {
    const [capturing, setCapturing] = useState<boolean>(false);
    const [time, setTime] = useState<number>(0);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(true);
    const [playScroll, setPlayScroll] = useState<boolean>(false);
    const [currentScroll, setCurrentScroll] = useState<number>(0);
    const webcamRef = useRef<any>(null);
    const mediaRecorderRef = useRef<any>(null);
    const listInnerRef = useRef<HTMLDivElement>(null);
    const [user,] = useLocalStorage(StorageKeys.USER, {});

    const { setSource, setOpenCam, recordedChunks, setRecordedChunks, setFileUpload, isIos } = useCamera();

    const svgIcon = () => (
        <svg
            width="100%"
            height="100%"
            className="svg"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
                    <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                    {mode === 'user' ? (
                        <ellipse cx="50%" cy="42%" rx="35%" ry="28%" />
                    ) : (
                        <rect x="10%" y="18%" width="80%" height="60%" fill="#000" />
                    )}
                </mask>
            </defs>
            <rect x="0" y="0" width="100%" height="100%" mask="url(#overlay-mask)" fillOpacity="0.7" />
        </svg>
    );

    const handleStart = () => {
        setCapturing(true);

        if (webcamRef?.current) {
            setIsActive(true);
            setIsPaused(false);
            setPlayScroll(true);

            mediaRecorderRef.current = new MediaRecorder(webcamRef?.current?.stream, {
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 2500000,
                mimeType: MediaRecorder.isTypeSupported("video/mp4") ? "video/mp4" : "video/webm"
            });
            mediaRecorderRef?.current?.addEventListener(
                "dataavailable",
                handleDataAvailable
            );
            mediaRecorderRef?.current?.start();
        }
    }

    const handleStop = () => {
        setIsPaused(!isPaused);
        mediaRecorderRef?.current && mediaRecorderRef?.current?.stop();
        setCapturing(false);
        setPlayScroll(false);
        setTime(0);

        listInnerRef?.current?.scroll({
            top: 0
        });
    }

    useEffect(() => {
        if (recordedChunks?.length > 0) {
            const blob = new Blob(recordedChunks, {
                type: MediaRecorder.isTypeSupported("video/mp4") ? "video/mp4" : "video/webm",
            });

            if (blob) {
                setSource(URL?.createObjectURL(blob));
                setFileUpload(blob);
                setOpenCam(false);
            }
        }
    }, [recordedChunks])

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    useEffect(() => {
        setCapturing(false);
        setRecordedChunks([]);
        setIsActive(false);
        setTime(0);
        setPlayScroll(false);
        setCurrentScroll(0);

        listInnerRef?.current?.scroll && listInnerRef?.current?.scroll({
            top: 0
        });
    }, []);

    useEffect(() => {
        let interval: any = null;

        if (isActive && !isPaused) {
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    useEffect(() => {
        let interval: any = null;

        if (playScroll) {
            interval = setInterval(() => {
                handleScroll();
            }, 85);
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [playScroll])

    const handleScroll = () => {
        if (playScroll) {
            if (listInnerRef?.current) {
                const { scrollTop, scrollHeight, clientHeight } = listInnerRef?.current;

                setCurrentScroll(scrollTop);

                if (scrollTop + scrollHeight == clientHeight) {
                    listInnerRef?.current?.scroll({
                        top: 0,
                        left: 0
                    });
                } else {
                    listInnerRef?.current?.scroll({
                        top: scrollTop + 1,
                        left: 0
                    });
                }
            }
        }
    }

    return (
        <div className="VideoComponent webcam-container" data-testid="VideoComponent">
            <Webcam
                audio={true}
                muted={true}
                videoConstraints={{
                    facingMode: mode,
                }}
                audioConstraints={{
                    sampleRate: 16000,
                    echoCancellation: true
                }}
                mirrored={mode === 'user'}
                className={'camera'}
                ref={webcamRef}
            >
                {() => (
                    <>
                        <Timer time={time} />

                        <div className="bottom-pos">
                            <div className="script" ref={listInnerRef}>
                                <br/>
                                <p>Eu</p>
                                <p><b>{user?.name}</b></p>
                                <p>CPF</p>
                                <p><b>{FormataCpf(user?.cpf)}</b></p>
                                <p>confirmo que estou ciente</p>
                                <p>de todas as informações</p>
                                <p>relacionadas ao produto</p>
                                <p><b>{user?.produto?.name}</b></p>
                                <p>de número</p>
                                <p className='letter-space'><b>{user?.propostaId}</b></p>
                                <p>que estou contratando</p>
                                <p>junto ao banco</p>
                                <p><b>{user?.produto?.financeira?.name}</b></p>
                                <p>Estou ciente que o</p>
                                <p>banco não solicita nenhuma</p>
                                <p>devolução via PIX ou</p>
                                <p>deposito em conta bancaria de terceiros.</p>
                                <p>Não estou realizando</p>
                                <p>nenhuma negociação paralela com terceiros.</p>
                                <p>Peço prosseguir com a contratação</p>
                                <p>nos termos informados.</p>
                                <br/>
                            </div>

                            <button
                                type="button"
                                className={`btn-rec ${capturing ? 'active' : ''}`}
                                onClick={() => !capturing ? handleStart() : handleStop()}
                            >
                                {/*<div></div>*/}
                                {capturing ? 'Finalizar Gravação' : 'Iniciar Gravação'}
                            </button>
                        </div>
                    </>
                )}
            </Webcam>

            {overlay && (
                <div className="overlay-container">
                    {svgIcon()}
                </div>
            )}
        </div>
    );
}

export default VideoComponent;
