import axios from "axios";
import Cookies from "js-cookie";
import {StorageKeys} from '../enums/StorageKeys';

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

const responseErrorHandler = async (error: any) => {
    if (error.response?.status === 401) {
        const pathname = window.location.pathname;

        if (!pathname.includes('/token-expired')) {
            if (localStorage !== null) {
                localStorage.clear();
            }
            window.location.href = `/token-expired`;
        }
    }

    return Promise.reject(error);
};

const api = axios.create({
    baseURL: process.env.REACT_APP_DINHEIROSIM_FORMALIZACAO_API,
    headers: headers,
    withCredentials: true,
});

api.interceptors.request.use(
    async (request: any) => {
        const token = (await Cookies.get(`${process.env.REACT_APP_NAME}${StorageKeys.AUTH}`)) || '';

        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }

        return request;
    },
    (error) => console.log(error)
);

api.interceptors.response.use(
    async (request) => request,
    (error) => responseErrorHandler(error)
);

export default api;
