import React, {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import AppButton from '../AppButton/AppButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useCamera} from '../../providers/CameraProvider';
import ArrowRight from '@mui/icons-material/ArrowRight';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import {useNavigate, useParams} from 'react-router-dom';
import './CaptureFrontDocument.scss';
import ListComponent from '../ListComponent/ListComponent';
import {captureFrontDocument} from '../../models/StepsSendRules';
import {StoreChecklist} from '../../models/StoreChecklist';
import {useStoreChecklist} from '../../hooks/useChecklist';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import SendConfirm from '../SendConfirm/SendConfirm';
import {CheckListItems} from '../../enums/ChecklistItems';
import ChecklistService from '../../services/ChecklistService';
import CircularProgress from '@mui/material/CircularProgress';
import UnicoCheck from '../UnicoCheck/UnicoCheck';
import {TipoDocumento} from '../../enums/UnicoCheck';
import DialogInfo from '../DialogInfo/DialogInfo';
import {CheckListItem} from '../../models/CheckListItem';
import CameraComponent from '../CameraComponent/CameraComponent';

interface CaptureFrontDocumentProps {
}

const CaptureFrontDocument: FC<CaptureFrontDocumentProps> = () => {
    const [payload, setPayload] = useState<StoreChecklist>({} as StoreChecklist);
    const {mutate, isError, isSuccess, isLoading: isLoadingSave, error} = useStoreChecklist(payload);
    const {source, sourceEncrypted, openCam, setOpenCam, resetCam, stopCam, isAndroid} = useCamera();
    const [showDialog, setShowDialog] = useState(false);
    const [guid] = useLocalStorage(StorageKeys.GUID, '');
    const {item} = useParams<string>();
    const [itemObj, setItemObj] = useState({} as CheckListItem);
    const [isLoading, setIsLoading] = useState(false);
    const [erroOrientacao, setErroOrientacao] = useState(false);
    const [erroMsg, setErroMsg] = useState('');
    const [showMsgVertical, setShowMsgVertical] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        mutate(payload);
    }

    const confirmSend = () => {
        setShowDialog(true);
    }

    const landscapeError = async () => {
        if (canTakePicture()) {
            setErroOrientacao(false);
            setOpenCam(true);
        } else {
            setShowMsgVertical(true);
        }
    }

    useEffect(() => {
        if (!canTakePicture()) {
            setErroOrientacao(true);
        }
    });

    const canTakePicture = (): boolean => {
        if (window.innerWidth >= 763) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        source && setPayload({
            guid: guid,
            item: +(item || '0'),
            file: source,
        });
    }, [source]);

    useEffect(() => {
        if (isSuccess) {
            ChecklistService.getChecklist(guid).then(x => {
                const _item = x?.produto?.checklist.filter((y: any) => y.tipo === CheckListItems.DOC_IDENTIDADE || y.tipo === CheckListItems.CHILDREN_RG);
                if (_item && _item[0].checked) {
                    navigate(`/checklist`);
                } else {
                    navigate(-1);
                }
            }).catch(e => {
                navigate(`/checklist`);
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            setShowError(true);
        }
    }, [isError]);

    useEffect(() => {
        stopCam();
        setIsLoading(true);
        ChecklistService.getChecklist(guid).then(x => {
            const _item = x?.produto?.checklist.filter((y: any) => y.tipo === CheckListItems.DOC_IDENTIDADE || y.tipo === CheckListItems.CHILDREN_RG);
            if (_item && _item[0].checked) {
                navigate(`/checklist`);
            }
            ChecklistService.get({guid, id: item || ''}).then(i => {
                setItemObj(i);
                setIsLoading(false);
            });
        }).catch(e => {
            setIsLoading(false);
            navigate(`/checklist`);
        });
        resetCam();
    }, []);

    return (
        <div className="CaptureFrontDocument" data-testid="CaptureFrontDocument">
            {isLoadingSave ?
                (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress/>
                    </Grid>
                ) :
                (<>
                    {isLoading && (
                        <Grid
                            item
                            xs={12}
                            alignItems="center"
                            flex={1}
                            flexDirection="row"
                            marginY="100px"
                        >
                            <CircularProgress color="error"/>
                        </Grid>
                    )}

                    {!openCam && !isLoading && (
                        <Grid
                            item
                            xs={12}
                            marginTop="20px"
                            className="content-body"
                        >
                            {!source && (<Typography variant="h4">Siga as instruções</Typography>)}
                            <br/>
                            {source ? (
                                <Typography variant="subtitle1" marginTop="20px">Verifique se a foto está nítida,<br/>caso
                                    precise, você pode tirar uma nova foto.</Typography>
                            ) : (
                                <>
                                    <Box
                                        justifyContent="center"
                                        flexDirection="column"
                                        className="img-box"
                                        marginTop="20px"
                                    >
                                        <Typography variant="h6">Se atente aos cuidados<br/>abaixo para tirar foto
                                            do<br/>seu
                                            documento:</Typography>

                                        <ListComponent data={captureFrontDocument}/>
                                    </Box>
                                </>
                            )}
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        marginTop="5px"
                        className="content-body"
                    >
                        {!openCam && source && !isLoading && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                className="img-box preview"
                            >
                                <img src={source} alt={'snap'} style={{width: '80%'}}/>
                            </Box>
                        )}
                    </Grid>
                    {!isLoading && (
                        <Grid
                            item
                            xs={12}
                            marginTop="30px"
                            className="content-body"
                        >
                            {openCam ? (
                                <CameraComponent
                                    mode={'environment'}
                                    overlay={true}
                                />
                            ) : (
                                <>
                                    {!source ? (
                                        <AppButton
                                            title={`Tirar foto ${itemObj?.tipo === CheckListItems.CHILDREN_RG_FRENTE ? 'da frente' :
                                                itemObj?.tipo === CheckListItems.CHILDREN_RG_VERSO ? 'do verso' : 'da CNH aberta'}`}
                                            onClick={() => {
                                                setOpenCam(true);
                                            }}
                                            icon={<ArrowRight fontSize="large"/>}
                                        />
                                    ) : (
                                        <>
                                            <AppButton
                                                title={'Enviar'}
                                                onClick={() => confirmSend()}
                                                icon={<FileUploadIcon fontSize="large"/>}
                                                disabled={isLoadingSave}
                                                style={{width: '80%'}}
                                            />

                                            <AppButton
                                                title={'Tirar novamente'}
                                                onClick={() => landscapeError()}
                                                icon={<FlipCameraIosIcon fontSize="large"/>}
                                                className="btn-gray"
                                                style={{width: '80%'}}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <SendConfirm status={showDialog} setStatus={setShowDialog}
                                         body={'Deseja realmente enviar a foto?'} handleAction={handleSubmit}/>
                            <DialogInfo show={showMsgVertical} setShow={setShowMsgVertical} titulo={"Atenção"}
                                        corpo={"Posicione o celular na vertical para continuar!"} botao={"Ok"}/>
                            <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                        corpo={error?.response?.data?.message || "Não foi possível enviar a imagem!"}
                                        botao={"Ok"}/>
                        </Grid>
                    )
                    }
                </>)}
        </div>
    );
}

export default CaptureFrontDocument;