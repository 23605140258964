import api from './Api';
import {GeoLocation} from '../models/GeoLocation';
import axios from "axios";

const GeoLocationService = {
    async send({guid, latitude, longitude, accuracy}: GeoLocation) {
        const {data: {data: response}} = await api.put(`api/formalizacao/${guid}/geo`, {
            latitude,
            longitude,
            accuracy,
        });
        return response;
    },

    async getLocationGoogle() {
        const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_KEY}`);
        return response.data;
    }
}

export default GeoLocationService;