import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { FC } from 'react';
import AppButton from '../AppButton/AppButton';
import './DialogInfo.scss';

interface DialogInfoProps {
  show: boolean,
  setShow?: any,
  titulo?: string,
  corpo?: string,
  botao?: string
}

const DialogInfo: FC<DialogInfoProps> = ({ show, setShow, titulo, corpo, botao }) => {
  return (
    <Dialog className="DialogInfo" data-testid="DialogInfo" open={show}>
      <DialogTitle id="alert-dialog-title">
        <span dangerouslySetInnerHTML={{ __html: titulo || '' }}></span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span dangerouslySetInnerHTML={{ __html: corpo || '' }}></span>
        </DialogContentText>
      </DialogContent>
      <DialogActions className='center' >
        <AppButton
          onClick={() => setShow(false)}
          title={botao}>
        </AppButton>
      </DialogActions>
    </Dialog>
  )
};

export default DialogInfo;
