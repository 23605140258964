import React, { FC, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MasterPage from '../MasterPage/MasterPage';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AppButton from '../../components/AppButton/AppButton';
import { useCamera } from '../../providers/CameraProvider';
import './Permissions.scss';

interface PermissionsProps { }

const Permissions: FC<PermissionsProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enableStream } = useCamera();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="Permissions"
        data-testid="Permissions"
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
            alignItems="center"
            flex={1}
            flexDirection="row"
            marginY="100px"
          >
            <CircularProgress color="error" />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            marginTop="30px"
            className="content-body"
          >
            <LocationOnIcon fontSize='large' className='icon' />

            <Typography variant="h4">Precisamos da sua<br />permissão para prosseguir</Typography>

            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
            >
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <PhotoCameraIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="Permitir a Câmera"
                />
              </ListItem>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <LocationOnIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="Permitir a Localização"
                />
              </ListItem>
            </List>

            <AppButton
                title={'Já permiti, quero continuar'}
                onClick={enableStream}
                style={{marginTop: 25}}
              />
          </Grid>
        )}
      </Grid>
    </MasterPage>
  );
}

export default Permissions;
