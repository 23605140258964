import {useMutation, useQuery} from 'react-query';
import {AxiosError} from 'axios';
import {ResponseData} from '../models/ResponseData';
import {GeoLocation} from '../models/GeoLocation';
import GeoLocationService from '../services/GeoLocationService';

interface Props {
    coords: GeoLocation;
}

export const useSendGeoLocation = ({coords}: Props) => {
    return useMutation<any, AxiosError<ResponseData>, Props>(
        'send-geolocation',
        () => GeoLocationService.send(coords),
        {
            onError: (error: AxiosError) => error.response?.data,
        },
    );
};

export const useSendGoogleLocation = () => useQuery<any | null, AxiosError<ResponseData>>(
    'get-geo-google',
    () => GeoLocationService.getLocationGoogle(),
    {
        enabled: false,
        onError: (error: AxiosError) => error.response?.data,
    }
);
