import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RootRoute from './routes/RootRoute';
import './App.scss';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
    const queryClient = new QueryClient();
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <RootRoute />
            </QueryClientProvider>
        </Router>
    );
}

export default App;
