import React, {FC, useEffect, useState} from 'react';
import './EnergyBill.scss';
import MasterPage from "../MasterPage/MasterPage";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Instruction from "../../components/Instruction/Instruction";
import UploadEnergyBill from "../../components/UploadEnergyBill/UploadEnergyBill";

interface EnergyBillProps {
}

const EnergyBill: FC<EnergyBillProps> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="EnergyBill"
                data-testid="EnergyBill"
            >
                {isLoading ? (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress color="error"/>
                    </Grid>
                ) : (
                    <Instruction>
                        <UploadEnergyBill/>
                    </Instruction>
                )}
            </Grid>
        </MasterPage>
    );
};

export default EnergyBill;
