import React, {FC, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import AppButton from '../AppButton/AppButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import ArrowRight from '@mui/icons-material/ArrowRight';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {useCamera} from '../../providers/CameraProvider';
import {useNavigate, useParams} from 'react-router-dom';
import ListComponent from '../ListComponent/ListComponent';
import {captureSelfie} from '../../models/StepsSendRules';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import './CaptureSelfie.scss';
import {useStoreChecklist} from '../../hooks/useChecklist';
import {StoreChecklist} from '../../models/StoreChecklist';
import ChecklistService from '../../services/ChecklistService';
import {CheckListItems} from '../../enums/ChecklistItems';
import SendConfirm from '../SendConfirm/SendConfirm';
import UnicoCheck from '../UnicoCheck/UnicoCheck';
import {CameraFrontalTraseira, TipoCamera, TipoDocumento} from '../../enums/UnicoCheck';
import DialogInfo from '../DialogInfo/DialogInfo';
import {CircularProgress} from '@mui/material';
import CameraComponent from "../CameraComponent/CameraComponent";

interface CaptureSelfieProps {
}

const CaptureSelfie: FC<CaptureSelfieProps> = () => {
    const [payload, setPayload] = useState<StoreChecklist>({} as StoreChecklist);
    const [{guid} = {} as any] = useLocalStorage(StorageKeys.USER, {});
    const {mutate, isSuccess, isError, isLoading, error} = useStoreChecklist(payload);
    const {source, sourceEncrypted, openCam, setOpenCam, resetCam, stopCam, isAndroid} = useCamera();
    const {item} = useParams<string>();
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [isLoadingCheckList, setIsLoadingCheckList] = useState(false);
    const [erroOrientacao, setErroOrientacao] = useState(false);
    const [erroMsg, setErroMsg] = useState('');
    const [showMsgVertical, setShowMsgVertical] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const reloadPage = useRef<any>();

    const handleSubmit = () => mutate(payload);

    const confirmSend = () => {
        setShowDialog(true);
    }

    const landscapeError = async () => {
        if (canTakePicture()) {
            clearTimeout(reloadPage.current);
            setErroOrientacao(false);
            setOpenCam(true);
        } else {
            setShowMsgVertical(true);
        }
    }

    useEffect(() => {
        if (!canTakePicture()) {
            setErroOrientacao(true);
        }
    });

    const canTakePicture = (): boolean => {
        if (window.innerWidth >= 763) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (erroOrientacao) {
            if (erroMsg === 'Câmera encerrada por inatividade' && !source) {
                reloadPage.current = setTimeout(() => window.location.reload(), 3000);
            }
        }
    }, [erroOrientacao]);

    useEffect(() => {
        source && setPayload({
            guid: guid,
            item: +(item || '0'),
            file: source,
        });
    }, [source]);

    useEffect(() => {
        isSuccess && navigate('/checklist');
        isError && setShowError(true);
    }, [isSuccess, isError]);

    useEffect(() => {
        stopCam();
        setIsLoadingCheckList(true);
        ChecklistService.getChecklist(guid).then(x => {
            const _item = x?.produto?.checklist.filter((y: any) => y.tipo === CheckListItems.SELFIE);
            if (_item && _item[0].checked) {
                navigate(`/checklist`);
            }
            setIsLoadingCheckList(false);
        }).catch(e => {
            setIsLoadingCheckList(false);
            navigate(`/checklist`);
        });
        resetCam();
    }, []);

    return (
        <div className="CaptureSelfie" data-testid="CaptureSelfie">
            {isLoading ?
                (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress/>
                    </Grid>
                ) :
                (<>

                    {!openCam && !isLoadingCheckList && (
                        <Grid
                            item
                            xs={12}
                            marginTop="20px"
                            className="content-body"
                        >
                            {!source && (<Typography variant="h4">Siga as instruções</Typography>)}
                            <br/>
                            {source ? (
                                <Typography variant="subtitle1" marginTop="20px">Verifique se a foto está nítida,<br/>caso
                                    precise, você pode tirar uma nova foto.</Typography>
                            ) : (
                                <>
                                    <Box
                                        justifyContent="center"
                                        flexDirection="column"
                                        className="img-box"
                                        marginTop="20px"
                                    >
                                        <Typography variant="h6">Se atente aos cuidados<br/>abaixo para tirar foto
                                            do<br/>seu
                                            documento:</Typography>

                                        <ListComponent
                                            data={captureSelfie}
                                        />
                                    </Box>
                                </>
                            )}
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        marginTop="20px"
                        className="content-body"
                    >
                        {!openCam && source && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                className="img-box preview"
                            >
                                <img src={source} alt={'snap'} style={{width: '90%'}}/>
                            </Box>
                        )}
                    </Grid>

                    {!isLoadingCheckList && (
                        <Grid
                            item
                            xs={12}
                            marginTop="30px"
                            className="content-body"
                        >
                            {openCam ? (
                                <CameraComponent
                                    mode={'user'}
                                    overlay={true}
                                />
                            ) : (
                                <>
                                    {!source ? (
                                        <AppButton
                                            title={'Começar'}
                                            onClick={() => setOpenCam(true)}
                                            icon={<ArrowRight fontSize="large"/>}
                                        />
                                    ) : (
                                        <>
                                            <AppButton
                                                title={'Enviar'}
                                                onClick={() => confirmSend()}
                                                icon={<FileUploadIcon fontSize="large"/>}
                                                style={{width: '80%'}}
                                                disabled={isLoading}
                                            />
                                            <AppButton
                                                title={'Tirar novamente'}
                                                onClick={() => landscapeError()}
                                                icon={<FlipCameraIosIcon fontSize="large"/>}
                                                className="btn-gray"
                                                style={{width: '80%'}}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <SendConfirm status={showDialog} setStatus={setShowDialog}
                                         body={'Deseja realmente enviar a foto?'} handleAction={handleSubmit}/>
                            <DialogInfo show={showMsgVertical} setShow={setShowMsgVertical} titulo={"Atenção"}
                                        corpo={"Posicione o celular na vertical para continuar!"} botao={"Ok"}/>
                            <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                        corpo={error?.response?.data?.message || "Não foi possível enviar a imagem!"}
                                        botao={"Ok"}/>
                        </Grid>
                    )}
                </>)}
        </div>
    );
}

export default CaptureSelfie;
