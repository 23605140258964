import React, { FC, useEffect, useState } from 'react';
import './IdentificationRGWelcome.scss';
import MasterPage from '../MasterPage/MasterPage';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppButton from '../../components/AppButton/AppButton';
import useLocalStorage from '../../hooks/useLocalStorage';
import { StorageKeys } from '../../enums/StorageKeys';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChecklist, useGetChecklistItem } from '../../hooks/useChecklist';
import { GetChecklistItem } from '../../models/GetChecklistItem';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckListItems } from '../../enums/ChecklistItems';

interface IdentificationRGWelcomeProps {
}

const IdentificationRGWelcome: FC<IdentificationRGWelcomeProps> = () => {
    const [guid] = useLocalStorage(StorageKeys.GUID, {});
    const { item } = useParams<string>();
    const [checkListItem, setCheckListItem] = useState({} as any);
    const { data, isLoading, error } = useGetChecklistItem({ guid: guid, id: item } as GetChecklistItem);
    const { data: dataAll } = useGetChecklist(guid);
    const navigate = useNavigate();

    useEffect(() => {
        const aux = dataAll?.produto?.checklist.filter(x => x.tipo === CheckListItems.CHILDREN_RG);
        aux && setCheckListItem(aux[0]);
        if (checkListItem.checked) {
            navigate(`/checklist`);
        }
    }, [dataAll]);

    return (
        <>
            {data ?
                (
                    <MasterPage>
                        <Grid
                            item
                            xs={12}
                            alignItems="center"
                            className="IdentificationRGWelcome"
                            data-testid="IdentificationRGWelcome"
                        >
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="30px"
                                    className="content-body"
                                >
                                    {isLoading ? (
                                        <Grid
                                            item
                                            xs={12}
                                            alignItems="center"
                                            flex={1}
                                            flexDirection="row"
                                            marginY="100px"
                                            display={'flex'}
                                            justifyContent="center"
                                        >
                                            <CircularProgress color="error" />
                                        </Grid>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="h4"
                                                dangerouslySetInnerHTML={{ __html: data?.name || '' }}
                                            />

                                            <img src={`${process.env.PUBLIC_URL}/assets/documento-foto-icon.png`}
                                                alt="Foto do Documento" className="img-principal" />

                                            <Typography
                                                variant="body1"
                                                dangerouslySetInnerHTML={{ __html: data?.subtitle || '' }}
                                            />
                                        </>
                                    )}
                                </Grid>

                                {!isLoading && (
                                    <Grid
                                        item
                                        xs={11}
                                        flexDirection="row"
                                        margin="20px auto"
                                    >
                                        {data?.children?.map(({ name, tipo, id, checked }: any) => (
                                            <AppButton
                                                disabled={checked}
                                                key={id}
                                                title={name}
                                                onClick={() => navigate(`/${tipo}/${id}/instruction`)}
                                                className="w-100"
                                            />
                                        ))}
                                    </Grid>
                                )}

                                {error && (<div>{error?.response?.data?.message}</div>)}
                            </>
                        </Grid>
                    </MasterPage>
                ) : (
                    <div
                        className="IdentificationRGWelcome"
                        data-testid="IdentificationRGWelcome"
                    ></div>
                )
            }
        </>
    );

};

export default IdentificationRGWelcome;
