import { CheckRounded, ClearRounded } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC } from 'react';
import AppButton from '../AppButton/AppButton';
import './SendConfirm.scss';

interface SendConfirmProps {
  status: boolean,
  setStatus: (status: boolean) => void,
  handleAction: () => void,
  body?: string
}

const SendConfirm: FC<SendConfirmProps> = ({ body, handleAction, status = false, setStatus }) => {

  const handleActionPlus = () => {
    setStatus(false);
    handleAction();
  }

  const handleClose = () => {
    setStatus(false);
  }

  return (
    <Dialog
      open={status}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="SendConfirm"
      data-testid="SendConfirm"
    >
      <DialogTitle id="alert-dialog-title">
        {"Atenção"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='center' >
        <AppButton
          onClick={handleClose}
          title={'Não'}
          className="btn-gray"
          icon={<ClearRounded fontSize="large" />}>
        </AppButton>
        <AppButton
          onClick={handleActionPlus}
          title={'Sim'}
          icon={<CheckRounded fontSize="large" />}>
        </AppButton>
      </DialogActions>
    </Dialog>
  );
}

export default SendConfirm;
