import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import MasterPage from '../MasterPage/MasterPage';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import NoticeLocation from '../../components/Notice/NoticeLocation/NoticeLocation';
import NoticeCamera from '../../components/Notice/NoticeCamera/NoticeCamera';
import NoticeInformation from '../../components/Notice/NoticeInformation/NoticeInformation';
import NoticeDebts from '../../components/Notice/NoticeDebts/NoticeDebts';
import { Clients } from '../../enums/Clients';
import { useStyle } from '../../providers/StyleProvider';

interface NoticeProps { }

const Notice: FC<NoticeProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { type } = useParams();
  const { sigla } = useStyle();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="Notice"
        data-testid="Notice"
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
            alignItems="center"
            flex={1}
            flexDirection="row"
            marginY="100px"
          >
            <CircularProgress color="error" />
          </Grid>
        ) : (
          <>
            {type === 'information' && (
              <NoticeInformation />
            )}

            {sigla === Clients.BEVIOFICIAL && type === 'debts' && (
              <NoticeDebts />
            )}

            {type === 'location' && (
              <NoticeLocation />
            )}

            {type === 'camera' && (
              <NoticeCamera />
            )}
          </>
        )}
      </Grid>
    </MasterPage>
  );
}

export default Notice;
