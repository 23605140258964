import { DadosBancarios } from "../models/DadosBancarios";
import api from "./Api";

export class BancoService {

    async getBancos(financeiraSigla: string) {
        const { data } = await api.post('api/formalizacao/bancos', { financeiraSigla });
        return [data.data, data.errors];
    };

    async getDadosBancarios(guid: string) {
        const { data } = await api.get(`api/formalizacao/${guid}/dados-bancarios`);
        return [data.data, data.errors];
    };

    async fetchDados(payload: DadosBancarios, guid: string) {
        try {
            const { data } = await api.put(`api/formalizacao/${guid}/dados-bancarios`, payload);
            return [data.data, data.errors];
        } catch (e: any) {
            return [e?.response?.data?.data,
            e?.response?.data?.errors ||
            e?.response?.data?.message];
        }

    };
};