import React, { FC, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MasterPage from '../MasterPage/MasterPage';
import Instruction from '../../components/Instruction/Instruction';
import CaptureSelfie from '../../components/CaptureSelfie/CaptureSelfie';

interface SelfieValidationProps { }

const SelfieValidation: FC<SelfieValidationProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <MasterPage>
      <Grid
        item
        xs={12}
        alignItems="center"
        className="SelfieValidation"
        data-testid="SelfieValidation"
      >
        {isLoading ? (
          <Grid
            item
            xs={12}
            alignItems="center"
            flex={1}
            flexDirection="row"
            marginY="100px"
          >
            <CircularProgress color="error" />
          </Grid>
        ) : (
          <Instruction>
            <CaptureSelfie />
          </Instruction>
        )}
      </Grid>
    </MasterPage>
  );
}

export default SelfieValidation;
