import {FC, useCallback, useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material';
import AppButton from '../../AppButton/AppButton';
import InfoIcon from '@mui/icons-material/Info';
import {useStyle} from '../../../providers/StyleProvider';
import {useNavigate} from 'react-router-dom';
import {Clients} from '../../../enums/Clients';
import {useSendTermo} from '../../../hooks/useTermo';
import TermoService from '../../../services/TermoService';
import {Termo} from '../../../models/Termo';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {StorageKeys} from '../../../enums/StorageKeys';
import DialogInfo from '../../DialogInfo/DialogInfo';
import './NoticeInformation.scss';
import CircularProgress from "@mui/material/CircularProgress";
import {Label} from "@mui/icons-material";

interface NoticeInformationProps {
}

const NoticeInformation: FC<NoticeInformationProps> = () => {
    const [isAceite, setIsAceite] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [isSetTermo, setIsSetTermo] = useState<boolean>(true);
    const [termo, setTermo] = useState<Termo>({} as Termo);
    const [user,] = useLocalStorage(StorageKeys.USER, {});
    const [termosStorage, setTermosStorage] = useLocalStorage(StorageKeys.TERMOS, {});
    const {mutate, isError, isLoading, isSuccess} = useSendTermo({termo});
    const {corPrincipal, sigla} = useStyle();
    const navigate = useNavigate();

    const handleChange = (event: any) => {
        setIsAceite(event?.target?.checked);
    };

    const handleSubmit = async () => {
        const obj = {...termosStorage, privacidade: true};

        await setTermosStorage(obj);

        (sigla !== Clients.BEVIOFICIAL) ? mutate({termo}) : navigate('/notice/debts');
    }

    useEffect(() => {
        if (isSuccess) {
            navigate('/notice/location');
        }

        isError && setShowError(true);

    }, [isSuccess, isError]);

    useEffect(() => {
        termo && setIsSetTermo(false);
    }, [termo]);

    const getPost = useCallback(async () => {
        setIsSetTermo(true);
        let response = await TermoService.getIpLocal();
        let attempts = 0;
        while (response?.code !== 200) {
            response = await TermoService.getIpLocal();
            attempts++;
            if (attempts === 3 && response?.code !== 200) {
                response.code = 200;
                response.data.ip = '1.1.1.1';
            }
        }

        setTermo({
            ip: response?.data?.ip,
            deviceId: navigator.userAgent,
            cpf: user.cpf,
            data: {privacidade: true},
            guid: user.guid
        });
    }, []);

    useEffect(() => {
        getPost().then();
    }, []);

    return (
        <Grid container direction="row" className="NoticeInformation"
              data-testid="NoticeInformation">
            {!isSetTermo ? (<>
                    <Grid item xs={12}>
                        <InfoIcon fontSize="large" className="icon"/>
                        <Typography variant="subtitle1">Termo de Uso e<br/>Política de Privacidade</Typography>
                        <Typography variant="body1" mt={2}>
                            {sigla !== Clients.BEVIOFICIAL && (
                                <>
                                    Visando à sua segurança, informamos que o Banco não entra em contato com seus clientes
                                    solicitando a
                                    devolução de valores do empréstimo, para cancelamento ou liquidação antecipada.<br/>
                                    Alertamos que o Sr(a) não realize depósitos em conta de terceiros. Qualquer negociação
                                    entre
                                    o
                                    Sr(a)
                                    e terceiros são de responsabilidade das partes.<br/>
                                    Reforçamos que o Sr(a) possui o prazo de 7 dias corridos a contar da data do recebimento
                                    do
                                    recurso
                                    para o cancelamento da operação, realizando a devolução deste recurso ao Banco.
                                </>
                            )}
                        </Typography>
                    </Grid>

                    {sigla !== Clients.BEVIOFICIAL ? (
                        <Grid item xs={12} mt={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAceite}
                                            onChange={handleChange}
                                            sx={{
                                                color: `${corPrincipal}`,
                                                '&.Mui-checked': {
                                                    color: `${corPrincipal}`,
                                                }
                                            }}
                                        />
                                    }
                                    label="Li e concordo com o Termo de Uso e Política de Privacidade"
                                />
                            </FormGroup>
                        </Grid>
                    ) : (
                        <Grid container direction='column' item xs={12} mt={8}>
                            <Grid item xs={1}>
                                <Checkbox
                                    checked={isAceite}
                                    onChange={handleChange}
                                    sx={{
                                        color: `${corPrincipal}`,
                                        '&.Mui-checked': {
                                            color: `${corPrincipal}`,
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid textAlign='left' item xs={11} mr={5}>
                                Li e concordo com o <a
                                href={`${process.env.PUBLIC_URL}/documents/politica-de-privacidade-e-termos-de-uso-bevi-3.pdf`}
                                target='_blank'>
                                Termo de Uso e Política de Privacidade
                            </a>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12} alignSelf="flex-start" marginTop="30px">
                        <AppButton
                            title="Continuar"
                            onClick={handleSubmit}
                            disabled={isLoading || !isAceite}
                            isLoading={isLoading}
                        />
                    </Grid>

                    <DialogInfo show={showError} setShow={setShowError} titulo={"Atenção"}
                                corpo={"Ocorreu um erro ao continuar, tente novamente!"} botao={"Ok"}/>
                </>)
                :
                (<Grid item xs={12}>
                    <CircularProgress/>
                </Grid>)}
        </Grid>
    );
}

export default NoticeInformation;
