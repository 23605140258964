import api from './Api';
import {StoreChecklist} from '../models/StoreChecklist';
import {GetChecklistItem} from '../models/GetChecklistItem';

const ChecklistService = {
    async getChecklist(guid: string) {
        const {data: {data}} = await api.get(`/api/formalizacao/${guid}/checklist`);
        return data;
    },
    async get({guid, id}: GetChecklistItem) {
        const {data: {data}} = await api.get(`/api/formalizacao/${guid}/checklist/${id}`);
        return data;
    },
    async storeChecklist(_data: StoreChecklist) {
        const formdata = new FormData();
        const isVideo = !!_data.fileUpload;
        _data.speech = isVideo;
        if (isVideo) {
            formdata.append("item", _data?.item?.toString());
            formdata.append("speech", 'true');
            formdata.append("fileUpload", _data?.fileUpload || '', `${_data.guid}.webm`);
        }
        const {data: {data}} = await api.post(`/api/formalizacao/${_data.guid}/checklist`, isVideo ? formdata : _data);
        return data;
    },
    async getClientDetails(guid: string) {
        const {data: {data}} = await api.get(`/api/formalizacao/${guid}/client`);
        return data;
    },
    async getResume(guid: string) {
        const {data: {data}} = await api.get(`/api/formalizacao/${guid}/resume`);
        return data;
    },
    async postConfirm(guid: string) {
        const {data: {data}} = await api.post(`/api/formalizacao/${guid}/confirm`);
        return data;
    },
}

export default ChecklistService;