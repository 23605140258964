import React, {FC, useEffect} from 'react';
import MasterPage from '../MasterPage/MasterPage';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import {useGetChecklist, useGetClientStyle} from '../../hooks/useChecklist';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert} from '@mui/material';
import useLocalStorage from '../../hooks/useLocalStorage';
import {StorageKeys} from '../../enums/StorageKeys';
import {Checklist} from '../../models/Checklist';
import {useStyle} from "../../providers/StyleProvider";
import {ClientStyle} from "../../models/ClientStyle";

interface SplashProps {
}

const Splash: FC<SplashProps> = () => {
    const {guid} = useParams<string>();
    const {setProduto, setClient, setLogoBranco, setLogoPrincipal, setCorPrincipal, setSigla} = useStyle();
    const [currentGuid, setValue] = useLocalStorage(StorageKeys.GUID, '');
    const [, setUser] = useLocalStorage(StorageKeys.USER, {});
    const navigate = useNavigate();

    const {isLoading, data, error, refetch, isError} = useGetChecklist(guid || '', (_data: Checklist) => {
    });

    useGetClientStyle(guid || '', (data: ClientStyle) => {
        data.produto && setProduto(data.produto);
        data.client && setClient(data.client);
        data.logoSecundaria && setLogoBranco(data.logoSecundaria);
        data.logo && setLogoPrincipal(data.logo);
        data.cor && setCorPrincipal(data.cor);
        data.sigla && setSigla(data.sigla);
    });

    useEffect(() => {
        if (!!guid) {
            if (!!currentGuid && guid !== currentGuid) {
                localStorage.clear();
            }
            refetch().then(x => {
                if (x?.data?.celular) {
                    setUser(x.data);
                    setValue(guid);
                    setTimeout(() => navigate(`/`), 3000);
                }
            });
        }
    }, [guid]);

    const switchComponent = () => {
        if (isLoading) {
            return <CircularProgress/>;
        }
        if (isError) {
            return <Alert severity="warning" style={{'textAlign': 'start'}}>{error.message}</Alert>;
        }
        return <Alert severity="success">Você será redirecionado...</Alert>;
    };

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="Splash"
                data-testid="Splash"
            >
                <Grid
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent={'center'}
                    flex={1}
                    flexDirection="row"
                    marginY="100px"
                >
                    {switchComponent()}
                </Grid>

            </Grid>
        </MasterPage>
    );
};

export default Splash;
