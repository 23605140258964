import React, { FC } from 'react';
import Webcam from 'react-webcam';
import { useCamera } from '../../providers/CameraProvider';
import AppButton from '../AppButton/AppButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './CameraComponent.scss';

interface CameraComponentProps {
  mode?: string
  overlay?: boolean,
  type?: string
}

const CameraComponent: FC<CameraComponentProps> = ({ overlay, mode = 'environment', type = 'document' }) => {
  const { setSource, setOpenCam } = useCamera();

  const svgIcon = () => (
    <svg
      width="100%"
      height="100%"
      className="svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          {mode === 'user' ? (
            <ellipse cx="50%" cy="50%" rx="35%" ry="28%" />
          ) : (
            <rect x="10%" y="18%" width="80%" height="60%" fill="#000" />
          )}
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" mask="url(#overlay-mask)" fillOpacity="0.7" />
    </svg>
  );

  return (
    <div className="CameraComponent webcam-container" data-testid="CameraComponent">
      <Webcam
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: mode
        }}
        mirrored={mode === 'user'}
        className={'camera'}
        screenshotQuality={1}
        minScreenshotHeight={1080}
        minScreenshotWidth={1080}
      >
        {({ getScreenshot }) => (
          <>
            <AppButton
              title={'Tirar foto'}
              onClick={() => {
                const imageSrc = getScreenshot();
                setSource(imageSrc as string);
                setOpenCam(false);
              }}
              icon={<CameraAltIcon fontSize='large' />}
              style={{ position: 'absolute', width: '70%', left: '15%', bottom: 30, zIndex: 9 }}
            />
          </>
        )}
      </Webcam>

      {overlay && (
        <div className="overlay-container">
          {svgIcon()}
        </div>
      )}
    </div>
  );
}

export default CameraComponent;
