import React, { FC } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AppButton from '../AppButton/AppButton';

import './ChecklistItens.scss';
import { CheckListItem } from '../../models/CheckListItem';
import { CheckListItems } from '../../enums/ChecklistItems';

interface Props {
    items: CheckListItem[];
}

const ChecklistItens: FC<Props> = ({ items }) => {
    const navigate = useNavigate();
    const openLink = (link: string, item: number) => {
        setTimeout(() => {
            if (link === CheckListItems.CHILDREN_RG) {
                navigate(`/${link}/${item}/instruction`);
            } else {
                navigate(`/${link}/${item}`);
            };
        }, 350);
    }

    return (
        <List
            className="ChecklistItens"
            data-testid="ChecklistItens"
            sx={{
                width: '100%',
                maxWidth: 380,
                bgColor: 'background.paper',
                margin: '0 auto',
            }}
        >
            {items?.map((item: CheckListItem, key: number) => {
                return (
                    <React.Fragment key={key}>
                        <Button
                            variant="text"
                            className="btn-link"
                            disabled={item.checked}
                            onClick={() => openLink(item.tipo, item.id)}
                        >
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    {item.checked ? (
                                        <h5 className="success">
                                            <Check />
                                        </h5>
                                    ) : (
                                        <h5>{key + 1}</h5>
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.name}
                                    secondary={item.subtitle}
                                />

                                {!item.checked && (
                                    <AppButton
                                        title="Iniciar"
                                        className="btn-inverse"
                                    />
                                )}
                            </ListItem>
                        </Button>

                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                )
            })}
        </List>
    );
}

export default ChecklistItens;
