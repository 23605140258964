import api from './Api';

const SmsService = {
    async sendResend(guid: string) {
        const {data} = await api.post(`api/sms/resend`, {guid: guid});
        return data.data;
    },
    async verify(guid: string, code: string) {
        const {data} = await api.post(`api/sms/verify`, {guid: guid, code: code});
        return data.data;
    },
};

export default SmsService;