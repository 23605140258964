import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MasterPage from '../MasterPage/MasterPage';
import AppButton from '../../components/AppButton/AppButton';
import Instruction from '../../components/Instruction/Instruction';
import CaptureFrontDocument from '../../components/CaptureFrontDocument/CaptureFrontDocument';
import _ from 'underscore';

import './IdentificationDocument.scss';
import useLocalStorage from '../../hooks/useLocalStorage';
import { StorageKeys } from '../../enums/StorageKeys';
import { CheckListItem } from '../../models/CheckListItem';
import { GetChecklistItem } from '../../models/GetChecklistItem';
import { useGetChecklistItem } from '../../hooks/useChecklist';
import { CheckListItems } from '../../enums/ChecklistItems';
const IdentificationDocument = () => {
    const [{ produto: { checklist } } = {} as any] = useLocalStorage(StorageKeys.USER, { produto: {} });
    const [guid] = useLocalStorage(StorageKeys.GUID, '');
    const [first, setFirst] = useState<CheckListItem>({} as CheckListItem);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [idHasBegan, setIdHasBegan] = useState<number>(0);
    const { item } = useParams();
    const { data } = useGetChecklistItem({ guid, id: item } as GetChecklistItem);
    const navigate = useNavigate();
    const { etapa } = useParams();

    useEffect(() => {
        const _data = data?.children?.filter(x => x.tipo === CheckListItems.CHILDREN_FOTO_DOC);
        _data && _data.length > 0 && _data[0]?.children?.map((x: any) => {
            if (x.children?.length !== undefined && x.children?.length > 0) {
                x.children?.map((y: any) => {
                    if (y.checked) {
                        setIdHasBegan(y.parent);
                    }
                });
            } else {
                if (x.checked) {
                    setIdHasBegan(x.id);
                }
            }
        });
    }, [data]);

    const getCurrent = async () => {
        if (!item) {
            return;
        }
        const _current = await _.findWhere(checklist, { id: +item });
        if (_current?.children?.length > 0) {
            const _first = _current.children[0];
            setFirst(_first);
        }
        const wait = 500;
        setTimeout(() => setIsLoading(false), wait);
    };

    useEffect(() => {
        checklist && item && getCurrent();
    }, [checklist, item]);

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="IdentificationDocument"
                data-testid="IdentificationDocument"
            >
                {isLoading ? (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress color="error" />
                    </Grid>
                ) : (
                    <>
                        {etapa === 'instruction' && (
                            <Instruction>
                                <CaptureFrontDocument />
                            </Instruction>
                        )}

                        {!etapa && first && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    marginTop="30px"
                                    className="content-body"
                                >
                                    <Typography
                                        variant="h4"
                                        dangerouslySetInnerHTML={{ __html: first?.name }}
                                    />

                                    <img src={`${process.env.PUBLIC_URL}/assets/documento-foto-icon.png`}
                                        alt="Foto do Documento" className="img-principal" />

                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: first.subtitle }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={11}
                                    flexDirection="row"
                                    margin="20px auto"
                                >

                                    {first?.children?.reverse()?.map((x: any) => (
                                        <AppButton
                                            key={x.id}
                                            title={x.name}
                                            disabled={!(idHasBegan === 0 || idHasBegan === x.id)}
                                            onClick={() => navigate(`/${x.tipo}/${x.id}/instruction`)}
                                            className="w-100"
                                        />))}
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </MasterPage>
    );
};

export default IdentificationDocument;
