import { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Icon } from '@mui/material';

import './ListComponent.scss';

interface ListComponentProps {
  data?: any[]
}

const ListComponent: FC<ListComponentProps> = ({ data }) => {
  const render = () => {
    return (
      <>
        {data?.map((item: any, key: number) => (
          <ListItem alignItems="center" key={key}>
            <ListItemAvatar>
              <Icon>{item.icon}</Icon>
            </ListItemAvatar>
            <ListItemText
              primary={item.descricao}
            />
          </ListItem>
        ))}
      </>
    );
  }

  return (
    <List
      className="ListComponent"
      data-testid="ListComponent"
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {render()}
    </List>
  );
}

export default ListComponent;
