import React, { FC } from 'react';
import './Instruction.scss';

interface InstructionProps extends React.HTMLProps<HTMLDivElement> {}

const Instruction: FC<InstructionProps> = ({ children }) => (
  <div className="Instruction" data-testid="Instruction">
    {children}
  </div>
);

export default Instruction;
