import {useState, useEffect} from 'react';

const useLocalStorage = (key: string, initialValue: any) => {
    const _key = `${process.env.REACT_APP_NAME}${key}`;
    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(_key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(_key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage;