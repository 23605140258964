export const captureFrontDocument = [
    {
        descricao: 'Tire o seu documento do plástico protetor.',
        icon: 'contacts'
    },
    {
        descricao: 'Verifique se a foto está nítida e sem reflexo.',
        icon: 'document_scanner'
    },
    {
        descricao: 'Verifique se a foto está enquadrada.',
        icon: 'aspect_ratio'
    },
];

export const captureSelfie = [
    {
        descricao: 'Procure um local iluminado e com o fundo neutro de preferência branco.',
        icon: 'co_present'
    },
    {
        descricao: 'Tire óculos escuros, boné, chapéu e outros acessórios que possam cobrir a sua face.',
        icon: 'person'
    },
    {
        descricao: 'Posicione o seu rosto dentro da área demarcada.',
        icon: 'aspect_ratio'
    },
];

export const captureEngine = [
    {
        descricao: 'Verifique se a foto aparece o motor completo.',
        icon: 'car_crash'
    },
    {
        descricao: 'Verifique se a foto está nítida e sem reflexo.',
        icon: 'document_scanner'
    },
    {
        descricao: 'Verifique se a foto está enquadrada.',
        icon: 'aspect_ratio'
    },
];

export const captureLicensePlate = [
    {
        descricao: 'A placa traseira do seu veículo precisa estar legível.',
        icon: 'car_crash'
    },
    {
        descricao: 'Os dois faróis traseiros precisam aparecer na imagem.',
        icon: 'aspect_ratio'
    },
    {
        descricao: 'A foto precisa ser colorida e sem filtros.',
        icon: 'document_scanner'
    },
];

export const uploadVehicleDocument = [
    {
        descricao: 'O tamanho máximo do arquivo é de 5mb.',
        icon: 'upload_file'
    },
    {
        descricao: 'O documento tem que ser do carro enviado na etapa anterior e deve estar em seu nome.',
        icon: 'assignment_ind'
    },
    {
        descricao: 'O documento deve estar nítido e as informações estar legíveis.',
        icon: 'highlight'
    },
];

export const uploadEnergyBillDocument = [
    {
        descricao: 'O tamanho máximo do arquivo é de 5mb.',
        icon: 'upload_file'
    },
    {
        descricao: 'A fatura deve pertencer a unidade consumidora informada e estar em seu nome.',
        icon: 'assignment_ind'
    },
    {
        descricao: 'A fatura deve estar nítida e as informações legíveis.',
        icon: 'highlight'
    },
];

export const videoContractRead = [
    {
        descricao: 'Faça a gravação em um local silencioso.',
        icon: 'hearing_disabled'
    },
    {
        descricao: 'Leia todo o texto corretamente conforme o apresentado.',
        icon: 'voice_chat'
    },
    {
        descricao: 'Faça tranquilo, você terá tempo suficiente para a leitura.',
        icon: 'insert_emoticon'
    },
    {
        descricao: 'Verifique se o vídeo está nítido.',
        icon: 'video_stable'
    },
];