import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { FC, useState } from 'react';
import CaptureFrontDocument from '../../components/CaptureFrontDocument/CaptureFrontDocument';
import Instruction from '../../components/Instruction/Instruction';
import MasterPage from '../MasterPage/MasterPage';

interface IdentificationRgVerseProps { }

const IdentificationRgVerse: FC<IdentificationRgVerseProps> = () => {
    const [isLoading,] = useState();

    return (
        <MasterPage>
            <Grid
                item
                xs={12}
                alignItems="center"
                className="IdentificationRgVerse"
                data-testid="IdentificationRgVerse"
            >
                {isLoading ? (
                    <Grid
                        item
                        xs={12}
                        alignItems="center"
                        flex={1}
                        flexDirection="row"
                        marginY="100px"
                    >
                        <CircularProgress color="error" />
                    </Grid>
                ) : (
                    <Instruction>
                        <CaptureFrontDocument />
                    </Instruction>
                )}
            </Grid>
        </MasterPage>
    );
};

export default IdentificationRgVerse;
